"use client";

import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  type ReactNode,
} from "react";
import { Modal } from "react-bootstrap";
import { CircleAlert, FileWarning, X } from "lucide-react";
import "./errorModalContext.scss";

interface ErrorModalContextType {
  showErrorModal: (
    title: string,
    description: string | ReactNode,
    type: "alert" | "warning"
  ) => void;
  hideErrorModal: () => void;
  onCloseCallback: (action: () => void) => void;
  errorModalComponent: ReactNode;
}

const ErrorModalContext = createContext<ErrorModalContextType | undefined>(
  undefined
);

export const useErrorContext = () => {
  const context = useContext(ErrorModalContext);
  if (!context) {
    throw new Error("useErrorContext must be used within a ErrorProvider");
  }
  return context;
};

interface ErrorModalProviderProps {
  children: ReactNode;
}

export const ErrorModalProvider: React.FC<ErrorModalProviderProps> = ({
  children,
}) => {
  const [showModal, setShowModal] = useState(false);

  const [description, setDescription] = useState<string | ReactNode>("");
  const [title, setTitle] = useState<string>("");

  const [type, setType] = useState<"alert" | "warning">("alert");

  const errorModalComponent = (
    <>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        centered
        backdrop={"static"}
        style={{ zIndex: "10000" }}
      >
        <Modal.Body className="text-center">
          <div className="close-button d-flex justify-content-end align-items-center">
            <X
              className="close-icon cursor-pointer"
              size={20}
              onClick={() => {
                setShowModal(false);
              }}
            />
          </div>
          <div className="d-flex gap-3 text-start">
            {type === "alert" ? (
              <div className="bg-alert-icon d-flex justify-content-center align-items-center">
                <CircleAlert className="alert-icon text-danger" />
              </div>
            ) : (
              <div className="bg-warning-icon d-flex justify-content-center align-items-center">
                <FileWarning className="warning-icon text-warning" />
              </div>
            )}
            <div>
              <div className="font-bold-600">{title}</div>
              <div className="error-modal-description">{description}</div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );

  const showErrorModal = useCallback(
    (
      title: string,
      description: string | ReactNode,
      type: "alert" | "warning"
    ) => {
      setShowModal(true);
      setTitle(title);
      setType(type);
      setDescription(description);
    },
    []
  );

  const hideErrorModal = useCallback(() => setShowModal(false), []);

  const onCloseCallback = useCallback((action: () => void) => {
    action();
  }, []);

  return (
    <ErrorModalContext.Provider
      value={{
        showErrorModal,
        hideErrorModal,
        errorModalComponent,
        onCloseCallback,
      }}
    >
      {children}
    </ErrorModalContext.Provider>
  );
};

import { Button, Container, Form } from "react-bootstrap";
import "./verifyPage.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { TemplateDocEnum } from "../../enum/templateType";

const LoginPage = () => {
  const navigate = useNavigate();

  const [verifyKey, setVerifyKey] = useState<string | undefined>(undefined);

  useEffect(() => {
    const userData = localStorage.getItem("user-key");
    const userTemplate = localStorage.getItem("user-template");
    if (userData !== null && userTemplate !== null) {
      if (userTemplate === TemplateDocEnum.DELTA) {
        navigate("/upload-file/delta", { replace: true });
      }
      return;
    }
  }, []);

  const onLogin = async () => {
    if (verifyKey === "delta") {
      localStorage.setItem("user-key", TemplateDocEnum.DELTA);
      localStorage.setItem("user-template", TemplateDocEnum.DELTA);
      navigate("/upload-file/delta", { replace: true });
    } else if (verifyKey === "king-power") {
      localStorage.setItem("user-key", TemplateDocEnum.KING_POWER);
      localStorage.setItem("user-template", TemplateDocEnum.KING_POWER);
      navigate("/upload-file/king-power", { replace: true });
    }
  };

  return (
    <>
      <Container className="login-page text-center col-lg-5 col-xxl-3">
        <h3>Welcome to Document OCR</h3>
        <Form
          className="text-start d-flex justify-content-center form-area"
          onSubmit={(e) => {
            e.preventDefault();
            onLogin();
          }}
        >
          <div className="col-10 my-4 py-4">
            <Form.Label className="form-label mt-3">
              Enter Verify Key
            </Form.Label>
            <Form.Group>
              <Form.Control
                type="text"
                value={verifyKey}
                placeholder="Verify Key"
                onChange={(evt) => {
                  setVerifyKey(evt.target.value);
                }}
                required
              />
            </Form.Group>
            <Button
              className="col-12 btn-secondary mt-5"
              type="submit"
              onClick={async () => onLogin()}
            >
              Verify
            </Button>
          </div>
        </Form>
      </Container>
    </>
  );
};

export default LoginPage;

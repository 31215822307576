export const checkOverlap = (
  box1: {
    x: number;
    y: number;
  }[],
  box2: {
    x: number;
    y: number;
  }[]
): boolean => {
  const box1MinX = Math.min(...box1.map((point) => point.x));
  const box1MaxX = Math.max(...box1.map((point) => point.x));
  const box1MinY = Math.min(...box1.map((point) => point.y));
  const box1MaxY = Math.max(...box1.map((point) => point.y));

  const box2MinX = Math.min(...box2.map((point) => point.x));
  const box2MaxX = Math.max(...box2.map((point) => point.x));
  const box2MinY = Math.min(...box2.map((point) => point.y));
  const box2MaxY = Math.max(...box2.map((point) => point.y));

  if (box1MaxX < box2MinX || box2MaxX < box1MinX) {
    return false;
  }

  if (box1MaxY < box2MinY || box2MaxY < box1MinY) {
    return false;
  }

  return true;
};

"use client";

import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  type ReactNode,
} from "react";
import { DisplayFileModeEnum } from "../enum/displayFileMode";

interface FileContextType {
  fileContext?: File;
  separateInvoice?: File;
  separatePackingList?: File;
  displayMode: DisplayFileModeEnum;
  setContextFile: (file: File | undefined) => void;
  setSepInvoiceFile: (file: File | undefined) => void;
  setSepPackListFile: (file: File | undefined) => void;
  setDisplayFileMode: (mode: DisplayFileModeEnum) => void;
}

const FileContext = createContext<FileContextType | undefined>(undefined);

export const useFileContext = () => {
  const context = useContext(FileContext);
  if (!context) {
    throw new Error("useLoadingContext must be used within a LoadingProvider");
  }
  return context;
};

interface FileProviderProps {
  children: ReactNode;
}

export const FileProvider: React.FC<FileProviderProps> = ({ children }) => {
  const [file, setFile] = useState<File | undefined>();

  const [separateInvoice, setSeparateInvoice] = useState<File | undefined>();
  const [separatePackingList, setSeparatePackingList] = useState<
    File | undefined
  >();

  const [displayMode, setDisplayMode] = useState<DisplayFileModeEnum>(
    DisplayFileModeEnum.INVOICE
  );

  const setNewFile = useCallback((file: File | undefined) => {
    setFile(file);
  }, []);

  const setSepInvoiceFile = useCallback((file: File | undefined) => {
    setSeparateInvoice(file);
  }, []);

  const setSepPackListFile = useCallback((file: File | undefined) => {
    setSeparatePackingList(file);
  }, []);

  const setDisplayFileMode = useCallback((mode: DisplayFileModeEnum) => {
    setDisplayMode(mode);
  }, []);

  return (
    <FileContext.Provider
      value={{
        fileContext: file,
        setContextFile: setNewFile,
        displayMode,
        setDisplayFileMode,
        separatePackingList,
        separateInvoice,
        setSepInvoiceFile,
        setSepPackListFile,
      }}
    >
      {children}
    </FileContext.Provider>
  );
};

import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router-dom";

const Layout = () => {
  const navigate = useNavigate();

  const userTemplate: string | null = localStorage.getItem("user-template");

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-between shadow-sm"
          >
            <div
              className="ms-4 px-2"
              style={{
                border: "1px solid gray",
                borderRadius: "5px 5px 5px 5px",
              }}
            >
              {userTemplate?.toUpperCase()}
            </div>
            <Nav>
              <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
    </>
  );
};

export default Layout;

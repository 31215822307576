import { Button, Container, Form, Modal } from "react-bootstrap";
import { useFileContext } from "../../context/fileContext";
import React, { ReactNode, useEffect, useState } from "react";
import axios from "axios";
import { DisplayFileModeEnum } from "../../enum/displayFileMode";
import { useNavigate } from "react-router-dom";
import "./previewAiResult.scss";
import { MoonLoader } from "react-spinners";
import { Document, Page } from "react-pdf";
import { IProcessResultProps } from "../../interface/processResult";
import { CircleCheck, Trash, X } from "lucide-react";
import DescriptionRateScore from "../../components/descriptionRateScore/descriptionRateScore";
import { createRoot } from "react-dom/client";
import csvIcon from "../../assets/csv-icon.svg";
import { exportDataToCsv, IExportCsvReturn } from "../../util/exportData";
import * as XLSX from "xlsx";
import { useErrorContext } from "../../context/errorModalContext/errorModalContext";
import {
  KeyProcessDetailEnum,
  KeyProcessHeaderEnum,
  ProcessTypeEnum,
} from "../../enum/processType";
import { separateInvQty } from "../../util/separateInvQty";
import {
  mockupDataTypeProcess1,
  mockupDataTypeProcess2,
} from "../../mockupData/v2/mockupDataV2";
import {
  groupingByRow,
  groupingByRowKingPower,
  IGroupByRow,
} from "./groupingRowData";
import { TemplateDocEnum } from "../../enum/templateType";
import {
  exportDataKKingPowerToCsv,
  IExportCsvKingPowerReturn,
} from "../../util/exportDataKingPower";
import { kingPowerDataset } from "../../mockupData/kingPower/mockupKingPower";
import { PDFDocument } from "pdf-lib";
import { optionLabelKingPower } from "../../constant/optionLabelKingPower";
import SearchableSelect from "../../components/sreachDropdown/sreachDropdown";
import { checkOverlap } from "../../util/checkOverlap";

const PreviewAiResult: React.FC = () => {
  const { displayMode, fileContext, separateInvoice, separatePackingList } =
    useFileContext();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [loadingWord, setLoadingWord] = useState<string>("Processing");

  const { errorModalComponent } = useErrorContext();

  const PdfRef = React.createRef<Document>();
  const PdfRefSepInvoice = React.createRef<Document>();
  const PdfRefSepPacking = React.createRef<Document>();

  const [numPages, setNumPages] = useState<number>(0);
  const [numPagesInvoice, setNumPagesInvoice] = useState<number>(0);
  const [numPagesPackingList, setNumPagesPackingList] = useState<number>(0);

  const [showExportModal, setShowExportModal] = useState<boolean>(false);

  const [dataExport, setDataExport] = useState<IExportCsvReturn>();

  const [dataExportKingPower, setDataExportKingPower] =
    useState<IExportCsvKingPowerReturn>();

  const [processResult, setProcessResult] = useState<IProcessResultProps[]>([]);
  const [processResultOcr, setProcessResultOcr] = useState<
    IProcessResultProps[]
  >([]);

  const [processResultInvoiceSep, setProcessResultInvoiceSep] = useState<
    IProcessResultProps[]
  >([]);

  const [groupByRowInvoice, setGroupByRowInvoice] = useState<IGroupByRow[]>([]);
  const [groupByRowPackingList, setGroupByRowPackingList] = useState<
    IGroupByRow[]
  >([]);

  const [processResultPackingSep, setProcessResultPackingSep] = useState<
    IProcessResultProps[]
  >([]);

  const [isHovered, setIsHovered] = useState(false);
  const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 });
  const [hoveredItemIndex, setHoveredItemIndex] = useState<number>(0);
  const [getDivId, setGetDivId] = useState<string>("");
  const [inputNewValue, setInputNewValue] = useState<string | undefined>();
  const [newLabelValue, setNewLabelValue] = useState<string | undefined>("");
  const [inputNewValueQty, setInputNewValueQty] = useState<
    string | undefined
  >();
  const [focusSection, setFocusSection] = useState<"invoice" | "packing-list">(
    "invoice"
  );

  const [focusOcrType, setFocusOcrType] = useState<"custom" | "ocr">("custom");

  const [invoiceId, setInvoiceId] = useState<string>("");

  const [template, setTemplate] = useState<TemplateDocEnum | null>();

  const userTemplate: string | null = localStorage.getItem("user-template");

  const userData: string | null = localStorage.getItem("user-key");

  useEffect(() => {
    setTemplate(userTemplate as TemplateDocEnum);
    if (userData === null) {
      navigate("/", { replace: true });
      return;
    } else if ((userData as TemplateDocEnum) !== userTemplate) {
      navigate("/", { replace: true });
      return;
    }
    if (!fileContext && !separateInvoice && !separatePackingList) {
      if (userTemplate === TemplateDocEnum.DELTA) {
        navigate("/upload-file/delta", { replace: true });
        return;
      } else if (userTemplate === TemplateDocEnum.KING_POWER) {
        navigate("/upload-file/king-power", { replace: true });
        return;
      }
    } else {
      startProcess();
    }
  }, []);

  const handleMouseEnter = (
    index: number,
    focus: "custom" | "ocr",
    from: "invoice" | "packing-list",
    data: IProcessResultProps[]
  ) => {
    setHoveredItemIndex(index);
    setFocusSection(from);

    if (fileContext && !separateInvoice && !separatePackingList) {
      if (focus === "custom") {
        if (data[index].key === KeyProcessDetailEnum.INVQTY) {
          setInputNewValue(
            separateInvQty(data[index].resultText ?? "").number as string
          );
          setInputNewValueQty(
            separateInvQty(data[index].resultText ?? "").characters as string
          );
        } else {
          setInputNewValue(data[index].resultText);
        }
        setNewLabelValue(data[index].key);
      } else {
        setInputNewValue(data[index].resultText);
        setNewLabelValue(undefined);
      }
    } else if (!fileContext && separateInvoice && separatePackingList) {
      if (from === "invoice") {
        if (data[index].key === KeyProcessDetailEnum.INVQTY) {
          setInputNewValue(
            separateInvQty(data[index].resultText ?? "").number as string
          );
          setInputNewValueQty(
            separateInvQty(data[index].resultText ?? "").characters as string
          );
        } else {
          setInputNewValue(data[index].resultText);
        }
      } else {
        setInputNewValue(data[index].resultText);
      }
    }
  };

  const handleMouseOut = () => {
    setHoveredItemIndex(0);
    setIsHovered(false);
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    renderHighLightBox();
  };

  const onDocumentInvoiceLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPagesInvoice(numPages);
  };

  const onDocumentPackingListLoadSuccess = ({
    numPages,
  }: {
    numPages: number;
  }) => {
    setNumPagesPackingList(numPages);
    renderHighLightBox();
  };

  const renderInvoiceGroupByRow = (
    classSelector: string,
    pdfHeightInvoice: number,
    pdfWidthInvoice: number
  ) => {
    groupByRowInvoice.forEach((item, index) => {
      const borderBox: ReactNode = (
        <div
          id={`group-by-row-resizable-box-invoice-result-${index}`}
          key={`group-by-row-resizable-box-invoice-result-${index}`}
          className={`group-by-row resizable-box-invoice cursor-pointer`}
          onClick={() => {
            // console.log(item);
          }}
          style={{
            position: "absolute",
            backgroundColor: `${
              index % 2 === 0 ? "rgb(0, 0, 0, 0.1)" : "rgb(0, 0, 0, 0.2)"
            }`,
            left: `0px`,
            top: `${item.minY * pdfHeightInvoice}px`,
            width: `${pdfWidthInvoice}px`,
            height: `${(item.maxY - item.minY) * pdfHeightInvoice}px`,
            zIndex: "999",
          }}
        ></div>
      );
      const container = document.querySelector(classSelector);
      const mountPoint = document.createElement("div");
      container?.appendChild(mountPoint);
      const root = createRoot(mountPoint);
      root.render(borderBox);
    });
  };

  const renderHighLightBox = () => {
    setTimeout(() => {
      if (displayMode === DisplayFileModeEnum.INVOICE && fileContext) {
        const pdfWidth =
          document.querySelector(".react-pdf__Page")!.clientWidth || 0;
        const pdfHeight =
          document.querySelector(".react-pdf__Page")!.clientHeight || 0;
        document.querySelectorAll(".resizable-box").forEach((item) => {
          item.remove();
        });
        renderInvoiceGroupByRow(".react-pdf__Page", pdfHeight, pdfWidth);
        processResult.forEach((item: IProcessResultProps, index: number) => {
          if (item.key === KeyProcessHeaderEnum.INVNO) {
            setInvoiceId(item.resultTextOriginal ?? "");
          }
          const calculateTop =
            item.hightLightZone.boundingBox[0].y * pdfHeight +
            (pdfHeight * parseInt(item.hightLightZone.page) + 1);
          const calculateHeight =
            (item.hightLightZone.boundingBox[2].y -
              item.hightLightZone.boundingBox[1].y) *
            pdfHeight;
          const calculateLeft = item.hightLightZone.boundingBox[0].x * pdfWidth;

          const borderBox: ReactNode = (
            <div
              id={`resizable-box-process-result-${index}`}
              className={`resizable-box cursor-pointer ${
                item.confidence < 0.5
                  ? "bad"
                  : item.confidence < 0.8
                  ? "improve"
                  : "good"
              }`}
              style={{
                position: "absolute",
                left: `${calculateLeft}px`,
                top: `${calculateTop}px`,
                width: `${
                  (item.hightLightZone.boundingBox[1].x -
                    item.hightLightZone.boundingBox[0].x) *
                  pdfWidth
                }px`,
                height: `${calculateHeight}px`,
                zIndex: "1000",
              }}
              onMouseEnter={(e) => {
                setFocusOcrType("custom");
                setGetDivId((e.target as HTMLDivElement).id);
                setIsHovered(true);
                setHoverPosition({ x: e.clientX, y: e.clientY });
                handleMouseEnter(index, "custom", "invoice", processResult);
              }}
            ></div>
          );

          const container = document.querySelector(".react-pdf__Page");
          const mountPoint = document.createElement("div");
          container?.appendChild(mountPoint);
          const root = createRoot(mountPoint);
          root.render(borderBox);
        });

        processResultOcr.forEach((item: IProcessResultProps, index: number) => {
          if (
            !processResult.some((customItem) => {
              const item1Page = item.hightLightZone.page.toString();
              const item2Page = customItem.hightLightZone.page;

              if (item1Page !== item2Page) {
                return false;
              }
              return checkOverlap(
                item.hightLightZone.boundingBox,
                customItem.hightLightZone.boundingBox
              );
            })
          ) {
            const calculateTop =
              item.hightLightZone.boundingBox[0].y * pdfHeight +
              pdfHeight * parseInt(item.hightLightZone.page);
            const calculateHeight =
              (item.hightLightZone.boundingBox[2].y -
                item.hightLightZone.boundingBox[1].y) *
              pdfHeight;
            const calculateLeft =
              item.hightLightZone.boundingBox[0].x * pdfWidth;

            const borderBox: ReactNode = (
              <div
                id={`resizable-box-ocr-process-result-${index}`}
                className={`resizable-box-ocr cursor-pointer`}
                style={{
                  position: "absolute",
                  left: `${calculateLeft}px`,
                  top: `${calculateTop}px`,
                  width: `${
                    (item.hightLightZone.boundingBox[1].x -
                      item.hightLightZone.boundingBox[0].x) *
                    pdfWidth
                  }px`,
                  height: `${calculateHeight}px`,
                  zIndex: "1000",
                }}
                onMouseEnter={(e) => {
                  setFocusOcrType("ocr");
                  handleMouseEnter(index, "ocr", "invoice", processResultOcr);
                  setGetDivId((e.target as HTMLDivElement).id);
                  setIsHovered(true);
                  setHoverPosition({ x: e.clientX, y: e.clientY });
                }}
              ></div>
            );

            const container = document.querySelector(".react-pdf__Page");
            const mountPoint = document.createElement("div");
            container?.appendChild(mountPoint);
            const root = createRoot(mountPoint);
            root.render(borderBox);
          }
        });
      } else if (
        displayMode === DisplayFileModeEnum.INVOICE_PACKING_LIST &&
        separateInvoice &&
        separatePackingList
      ) {
        const pdfWidthInvoice =
          document.querySelector(".invoice-page")!.clientWidth || 0;
        const pdfHeightInvoice =
          document.querySelector(".invoice-page")!.clientHeight || 0;
        document.querySelectorAll(".resizable-box-invoice").forEach((item) => {
          item.remove();
        });

        const pdfWidthPacking =
          document.querySelector(".packing-list-page")!.clientWidth || 0;
        const pdfHeightPacking =
          document.querySelector(".packing-list-page")!.clientHeight || 0;
        document
          .querySelectorAll(".resizable-box-packing-list")
          .forEach((item) => {
            item.remove();
          });
        renderInvoiceGroupByRow(
          ".invoice-page",
          pdfHeightInvoice,
          pdfWidthInvoice
        );
        renderInvoiceGroupByRow(
          ".packing-list-page",
          pdfHeightPacking,
          pdfWidthPacking
        );
        processResultInvoiceSep.forEach(
          (item: IProcessResultProps, index: number) => {
            if (item.key === KeyProcessHeaderEnum.INVNO) {
              setInvoiceId(item.resultTextOriginal ?? "");
            }
            const filterDes = processResultPackingSep.filter(
              (e) => e.key === KeyProcessDetailEnum.DES
            );
            const filterDesInvoice = processResultInvoiceSep.filter(
              (e) => e.key === KeyProcessDetailEnum.DES1
            );

            let findNotInclude: IProcessResultProps[] = [];

            if (filterDes.length !== filterDesInvoice.length) {
              findNotInclude = [...findNotInclude, ...filterDesInvoice];
            }

            const borderBox: ReactNode = (
              <div
                id={`resizable-box-invoice-result-${index}`}
                className={`resizable-box-invoice ${
                  findNotInclude.includes(item) ? "err" : ""
                } cursor-pointer ${
                  item.confidence < 0.5
                    ? "bad"
                    : item.confidence < 0.8
                    ? "improve"
                    : "good"
                }`}
                style={{
                  position: "absolute",
                  left: `${
                    item.hightLightZone.boundingBox[0].x * pdfWidthInvoice
                  }px`,
                  top: `${
                    item.hightLightZone.boundingBox[0].y * pdfHeightInvoice +
                    (pdfHeightInvoice * parseInt(item.hightLightZone.page) + 1)
                  }px`,
                  width: `${
                    (item.hightLightZone.boundingBox[1].x -
                      item.hightLightZone.boundingBox[0].x) *
                    pdfWidthInvoice
                  }px`,
                  height: `${
                    (item.hightLightZone.boundingBox[2].y -
                      item.hightLightZone.boundingBox[1].y) *
                    pdfHeightInvoice
                  }px`,
                  zIndex: "1000",
                }}
                onMouseEnter={(e) => {
                  setFocusOcrType("custom");
                  setGetDivId((e.target as HTMLDivElement).id);
                  setIsHovered(true);
                  setHoverPosition({ x: e.clientX, y: e.clientY });
                  handleMouseEnter(
                    index,
                    "custom",
                    "invoice",
                    processResultInvoiceSep
                  );
                }}
              ></div>
            );

            const container = document.querySelector(".invoice-page");
            const mountPoint = document.createElement("div");
            container?.appendChild(mountPoint);
            const root = createRoot(mountPoint);
            root.render(borderBox);
          }
        );

        processResultPackingSep.forEach(
          (item: IProcessResultProps, index: number) => {
            if (
              item.key !== KeyProcessDetailEnum.DES &&
              item.key !== KeyProcessDetailEnum.PRODUCT_ID &&
              item.key !== KeyProcessDetailEnum.GROUP_ROW_INDEX
            ) {
              const filterDesInvoice = processResultInvoiceSep.filter(
                (e) => e.key === KeyProcessDetailEnum.DES1
              );

              const filterWeightPack = processResultPackingSep.filter(
                (e) => e.key === KeyProcessDetailEnum.WEIGHT
              );

              let findNotInclude: IProcessResultProps[] = [];

              if (filterWeightPack.length !== filterDesInvoice.length) {
                findNotInclude = [...findNotInclude, ...filterWeightPack];
              }
              const borderBox: ReactNode = (
                <div
                  id={`resizable-box-packing-result-${index}`}
                  className={`resizable-box-invoice ${
                    findNotInclude.includes(item) ? "err" : ""
                  } cursor-pointer ${
                    item.confidence < 0.5
                      ? "bad"
                      : item.confidence < 0.8
                      ? "improve"
                      : "good"
                  }`}
                  style={{
                    position: "absolute",
                    left: `${
                      item.hightLightZone.boundingBox[0].x * pdfWidthPacking
                    }px`,
                    top: `${
                      item.hightLightZone.boundingBox[0].y * pdfHeightPacking +
                      (pdfHeightPacking * parseInt(item.hightLightZone.page) +
                        1)
                    }px`,
                    width: `${
                      (item.hightLightZone.boundingBox[1].x -
                        item.hightLightZone.boundingBox[0].x) *
                      pdfWidthPacking
                    }px`,
                    height: `${
                      (item.hightLightZone.boundingBox[2].y -
                        item.hightLightZone.boundingBox[1].y) *
                      pdfHeightPacking
                    }px`,
                    zIndex: "1000",
                  }}
                  onMouseEnter={(e) => {
                    setFocusOcrType("custom");
                    setGetDivId((e.target as HTMLDivElement).id);
                    setIsHovered(true);
                    setHoverPosition({ x: e.clientX, y: e.clientY });
                    handleMouseEnter(
                      index,
                      "custom",
                      "packing-list",
                      processResultPackingSep
                    );
                  }}
                ></div>
              );

              const container = document.querySelector(".packing-list-page");
              const mountPoint = document.createElement("div");
              container?.appendChild(mountPoint);
              const root = createRoot(mountPoint);
              root.render(borderBox);
            }
          }
        );
      }
    }, 500);
  };

  const uploadToDocumentAi = async (
    typeProcess: number,
    formData: FormData,
    currentPageNumber?: number
  ): Promise<{
    responseOcr: IProcessResultProps[];
    responseTextResult: IProcessResultProps[];
    responseGroup: IGroupByRow[];
  }> => {
    let returnData: IProcessResultProps[] = [];
    let returnGroupData: IGroupByRow[] = [];
    let returnOcrData: IProcessResultProps[] = [];

    try {
      setIsLoading(true);

      const userTemplate = localStorage.getItem("user-template");
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND!}/upload-file`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            "x-rapidapi-host": "file-upload8.p.rapidapi.com",
          },
          params: {
            typeProcess: typeProcess,
            template: userTemplate,
          },
        }
      );

      if (typeProcess === 1) {
        returnData = response.data;
      } else if (typeProcess === 2) {
        const data = groupingByRow(response.data, ProcessTypeEnum.PACKING_LIST);
        returnGroupData = data;
        returnData = response.data;
      }

      const responseOcr = await axios.post(
        `${process.env.REACT_APP_BACKEND!}/upload-file-text-ocr`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            "x-rapidapi-host": "file-upload8.p.rapidapi.com",
          },
          params: {
            typeProcess: typeProcess,
            template: userTemplate,
          },
        }
      );

      const responseOcrData = responseOcr.data as IProcessResultProps[];

      if (fileContext && !separateInvoice && !separatePackingList) {
        const convertDataOcr: IProcessResultProps[] = responseOcrData.map(
          (element: IProcessResultProps) => {
            return {
              ...element,
              hightLightZone: {
                boundingBox: element.hightLightZone.boundingBox,
                page: currentPageNumber
                  ? (
                      parseInt(element.hightLightZone.page) -
                      1 +
                      currentPageNumber
                    ).toString()
                  : (parseInt(element.hightLightZone.page) - 1).toString(),
              },
              resultTextOriginal: element.resultText,
              edited: false,
            };
          }
        );

        returnOcrData = convertDataOcr;

        const addOriginal: IProcessResultProps[] = response.data.map(
          (element: IProcessResultProps) => {
            return {
              ...element,
              hightLightZone: {
                boundingBox: element.hightLightZone.boundingBox,
                page: currentPageNumber
                  ? (
                      parseInt(element.hightLightZone.page) + currentPageNumber
                    ).toString()
                  : element.hightLightZone.page,
              },
              resultTextOriginal: element.resultText,
              edited: false,
            };
          }
        );
        const data =
          userTemplate === TemplateDocEnum.KING_POWER
            ? groupingByRowKingPower(addOriginal)
            : groupingByRow(addOriginal, ProcessTypeEnum.INVOICE);
        returnGroupData = data;
        returnData = addOriginal;
      } else if (!fileContext && separateInvoice && separatePackingList) {
        if (typeProcess === 1) {
          const addOriginal: IProcessResultProps[] = response.data.map(
            (element: IProcessResultProps) => {
              return {
                ...element,
                resultTextOriginal: element.resultText,
                edited: false,
              };
            }
          );
          const data = groupingByRow(response.data, ProcessTypeEnum.INVOICE);
          returnData = addOriginal;
          returnGroupData = data;
          setGroupByRowInvoice(data);
          setProcessResultInvoiceSep(addOriginal);
        } else if (typeProcess === 2) {
          const addOriginal: IProcessResultProps[] = response.data.map(
            (element: IProcessResultProps) => {
              return {
                ...element,
                resultTextOriginal: element.resultText,
                edited: false,
              };
            }
          );
          const data = groupingByRow(
            response.data,
            ProcessTypeEnum.PACKING_LIST
          );
          returnData = addOriginal;
          returnGroupData = data;
          setGroupByRowPackingList(data);
          setProcessResultPackingSep(addOriginal);
        }
      }
    } catch (error) {
      await uploadToDocumentAi(typeProcess, formData, currentPageNumber);
    } finally {
      return {
        responseOcr: returnOcrData,
        responseTextResult: returnData,
        responseGroup: returnGroupData,
      };
    }
  };

  const startProcess = async () => {
    if (fileContext && !separateInvoice && !separatePackingList) {
      if (!fileContext) return;

      let currentPage = 0;

      const reader = new FileReader();
      reader.onload = async () => {
        const pdfDoc = await PDFDocument.load(reader.result!);

        const docSeparateList: PDFDocument[] = [];

        if (pdfDoc.getPageCount() > 3) {
          for (let i = currentPage; i <= pdfDoc.getPageCount(); i++) {
            if (i - currentPage === 3) {
              let groupPage = await PDFDocument.create();
              for (let indexPage = currentPage; indexPage < i; indexPage++) {
                const [copiedPage] = await groupPage.copyPages(pdfDoc, [
                  indexPage,
                ]);
                groupPage.addPage(copiedPage);
              }
              docSeparateList.push(groupPage);
              currentPage = i;
            } else if (
              pdfDoc.getPageCount() - currentPage < 3 &&
              pdfDoc.getPageCount() > 3
            ) {
              let groupPage = await PDFDocument.create();
              for (let indexPage = currentPage; indexPage < i; indexPage++) {
                const [copiedPage] = await groupPage.copyPages(pdfDoc, [
                  indexPage,
                ]);
                groupPage.addPage(copiedPage);
              }
              docSeparateList.push(groupPage);
              currentPage = i;
            }
          }
        } else {
          docSeparateList.push(pdfDoc);
        }

        let countNumberPage = 0;

        let result: IProcessResultProps[] = [];
        let resultOcr: IProcessResultProps[] = [];
        let resultGroupByRow: IGroupByRow[] = [];

        const preparePromise: {
          currentPage: number | undefined;
          formData: FormData;
        }[] = [];

        for (let index = 0; index < docSeparateList.length; index++) {
          const groupBytes = await docSeparateList[index].save();
          const newGroupFile = new File([groupBytes], `${index}`, {
            type: "application/pdf",
          });
          const formData = new FormData();
          formData.append("file", newGroupFile);

          preparePromise.push({
            currentPage:
              pdfDoc.getPageCount() > 3 ? countNumberPage : undefined,
            formData: formData,
          });
          countNumberPage += docSeparateList[index].getPageCount();
        }

        Promise.all(
          preparePromise.map(
            async (e) => await uploadToDocumentAi(1, e.formData, e.currentPage)
          )
        ).then((values) => {
          values.forEach((e) => {
            resultOcr = [...resultOcr, ...e.responseOcr];
            result = [...result, ...e.responseTextResult];
            resultGroupByRow = [...resultGroupByRow, ...e.responseGroup];
          });

          setGroupByRowInvoice(resultGroupByRow);
          setProcessResult(result);
          setProcessResultOcr(resultOcr);

          setTimeout(() => {
            setLoadingWord("Mapping data");
          }, 500);
          setTimeout(() => {
            setLoadingWord("Done!");
            setTimeout(() => {
              setIsLoading(false);
            }, 500);
          }, 500);
        });
      };
      reader.readAsArrayBuffer(fileContext);
    } else if (!fileContext && separateInvoice && separatePackingList) {
      const formInvoiceData = new FormData();
      formInvoiceData.append("file", separateInvoice);

      const formPackingListData = new FormData();
      formPackingListData.append("file", separatePackingList);

      setLoadingWord("Invoice in progress...");

      await uploadToDocumentAi(1, formInvoiceData);

      setLoadingWord("Packing List in progress...");

      await uploadToDocumentAi(2, formPackingListData);

      setTimeout(() => {
        setLoadingWord("Mapping data");
      }, 500);
      setTimeout(() => {
        setLoadingWord("Done!");
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      }, 500);
    }
  };

  const renderPreviewResult = (): ReactNode => {
    if (fileContext && !separateInvoice && !separatePackingList) {
      return (
        <div className="preview-doc-area py-2">
          <div className="header-section ps-4 pt-2">
            <div className="header font-bold-700">Invoice</div>
            <DescriptionRateScore />
          </div>
          <div className="preview-result-container mx-auto">
            <div className="pdf-document">
              <Document
                ref={PdfRef}
                file={fileContext}
                onLoadSuccess={onDocumentLoadSuccess}
                className="px-4"
              >
                {fileContext &&
                  Array.from(new Array(numPages), (el, index) => (
                    <div className="body-page">
                      <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                    </div>
                  ))}
              </Document>
            </div>
          </div>
        </div>
      );
    } else if (!fileContext && separateInvoice && separatePackingList) {
      return (
        <div className="preview-doc-area py-2 d-flex">
          <div className="col-6">
            <div className="header-section ps-4 pt-2">
              <div className="header font-bold-700">Invoice</div>
              <DescriptionRateScore />
            </div>
            <div className="d-flex">
              <div className="preview-result-container mx-auto">
                <div className="pdf-document d-flex tex-center justify-content-center align-items-center">
                  <Document
                    ref={PdfRefSepInvoice}
                    file={separateInvoice}
                    onLoadSuccess={onDocumentInvoiceLoadSuccess}
                    className="px-4"
                  >
                    {separateInvoice &&
                      Array.from(new Array(numPagesInvoice), (el, index) => (
                        <div className="body-page">
                          <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            className="invoice-page"
                          />
                        </div>
                      ))}
                  </Document>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="header-section ps-4 pt-2">
              <div className="header font-bold-700">Packing List</div>
              <DescriptionRateScore />
            </div>
            <div className="d-flex">
              <div className="preview-result-container mx-auto">
                <div className="pdf-document d-flex tex-center justify-content-center align-items-center">
                  <Document
                    ref={PdfRefSepPacking}
                    file={separatePackingList}
                    onLoadSuccess={onDocumentPackingListLoadSuccess}
                    className="px-4"
                  >
                    {separatePackingList &&
                      Array.from(
                        new Array(numPagesPackingList),
                        (el, index) => (
                          <div className="body-page">
                            <Page
                              key={`page_${index + 1}`}
                              pageNumber={index + 1}
                              className="packing-list-page"
                            />
                          </div>
                        )
                      )}
                  </Document>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <></>;
  };

  const handleRenderDataHover = (): IProcessResultProps => {
    switch (displayMode) {
      case DisplayFileModeEnum.INVOICE:
        if (focusOcrType === "custom") {
          return processResult[hoveredItemIndex];
        } else {
          return processResultOcr[hoveredItemIndex];
        }
      default:
        if (focusSection === "invoice") {
          return processResultInvoiceSep[hoveredItemIndex];
        } else {
          return processResultPackingSep[hoveredItemIndex];
        }
    }
  };

  return (
    <>
      <Container
        className="preview-ai-result-page p-2 px-5"
        fluid={displayMode === DisplayFileModeEnum.INVOICE_PACKING_LIST}
      >
        {isLoading ? (
          <div className="loading-container mx-auto d-flex align-items-center">
            <div className="mx-auto loading">
              <MoonLoader
                loading
                speedMultiplier={0.3}
                className="mx-auto"
                color="#1849d6"
              />
              <div className="text-center mt-4">{loadingWord}</div>
            </div>
          </div>
        ) : (
          <div className="main-preview-section">
            {renderPreviewResult()}

            <div className="action-button-section d-flex gap-3 p-3 mt-2 justify-content-end">
              <Button
                variant="primary"
                onClick={() => {
                  if (template === TemplateDocEnum.DELTA) {
                    navigate("/upload-file/delta", { replace: true });
                  } else if (template === TemplateDocEnum.KING_POWER) {
                    navigate("/upload-file/king-power", { replace: true });
                  }
                }}
              >
                Back to Home
              </Button>
              <Button
                variant="primary"
                onClick={async () => {
                  if (template === TemplateDocEnum.KING_POWER) {
                    const data = exportDataKKingPowerToCsv(processResult);
                    setDataExportKingPower(data);
                    await new Promise<void>((resolve, reject) => {
                      try {
                        XLSX.writeFile(
                          data.dataInvoiceCsv,
                          `CHANEL ${data?.firstInvoiceNo}.xlsx`,
                          {
                            bookType: "xlsx",
                            type: "buffer",
                          }
                        );

                        resolve();
                      } catch (error) {
                        reject(error);
                      }
                    }).then(() => {
                      setShowExportModal(true);
                    });

                    return;
                  }
                  if (fileContext && !separateInvoice && !separatePackingList) {
                    const data = exportDataToCsv(processResult);

                    setDataExport(data);

                    await new Promise<void>((resolve, reject) => {
                      try {
                        XLSX.writeFile(
                          data.headerCsv,
                          `${invoiceId}_Header.csv`,
                          {
                            bookType: "csv",
                            type: "buffer",
                          }
                        );

                        XLSX.writeFile(data.detailCsv, `${invoiceId}.csv`, {
                          bookType: "csv",
                          type: "buffer",
                        });
                        resolve();
                      } catch (error) {
                        reject(error);
                      }
                    }).then(() => {
                      setShowExportModal(true);
                    });
                  } else if (
                    !fileContext &&
                    separateInvoice &&
                    separatePackingList
                  ) {
                    const data = exportDataToCsv(
                      processResultInvoiceSep,
                      processResultPackingSep
                    );

                    setDataExport(data);

                    await new Promise<void>((resolve, reject) => {
                      try {
                        XLSX.writeFile(
                          data.headerCsv,
                          `${invoiceId}_Header.csv`,
                          {
                            bookType: "csv",
                            type: "buffer",
                          }
                        );

                        XLSX.writeFile(data.detailCsv, `${invoiceId}.csv`, {
                          bookType: "csv",
                          type: "buffer",
                        });
                        resolve();
                      } catch (error) {
                        reject(error);
                      }
                    }).then(() => {
                      setShowExportModal(true);
                    });
                  }
                }}
              >
                Export
              </Button>
            </div>
          </div>
        )}

        {isHovered && (
          <div
            className="overlay"
            style={{
              transition: "all 0.23s",
              position: "absolute",
              top: hoverPosition.y,
              left: hoverPosition.x,
            }}
          >
            <div
              className="mention-box p-2"
              style={{
                position: "absolute",
                zIndex: "100001",
              }}
              onMouseLeave={handleMouseOut}
            >
              <div
                className={`d-flex justify-content-between align-items-center`}
              >
                <div
                  className={`${
                    handleRenderDataHover().confidence < 0.5
                      ? "bad"
                      : handleRenderDataHover().confidence < 0.8
                      ? "improve"
                      : "good"
                  } font-bold-600`}
                >
                  Confidence{" "}
                  {(handleRenderDataHover().confidence * 100).toFixed(2)}%
                </div>
                <div className="text-danger cursor-pointer">
                  <Trash
                    size={16}
                    onClick={() => {
                      if (focusOcrType === "custom") {
                        if (displayMode === DisplayFileModeEnum.INVOICE) {
                          const clone = [...processResult];
                          clone[hoveredItemIndex].resultText = "";
                          setProcessResult(clone);
                          const divToRemove = document.getElementById(getDivId);
                          if (divToRemove?.parentNode) {
                            divToRemove.parentNode.removeChild(divToRemove);
                          }
                        } else if (
                          displayMode ===
                          DisplayFileModeEnum.INVOICE_PACKING_LIST
                        ) {
                          if (focusSection === "invoice") {
                            const clone = [...processResultInvoiceSep];
                            clone[hoveredItemIndex].resultText = "";
                            setProcessResultInvoiceSep(clone);
                            const divToRemove =
                              document.getElementById(getDivId);
                            if (divToRemove?.parentNode) {
                              divToRemove.parentNode.removeChild(divToRemove);
                            }
                          } else {
                            const clone = [...processResultPackingSep];
                            clone[hoveredItemIndex].resultText = "";
                            setProcessResultPackingSep(clone);
                            const divToRemove =
                              document.getElementById(getDivId);
                            if (divToRemove?.parentNode) {
                              divToRemove.parentNode.removeChild(divToRemove);
                            }
                          }
                        }
                      } else {
                        const clone = [...processResultOcr];
                        clone[hoveredItemIndex].resultText = "";
                        setProcessResultOcr(clone);
                        const divToRemove = document.getElementById(getDivId);
                        if (divToRemove?.parentNode) {
                          divToRemove.parentNode.removeChild(divToRemove);
                        }
                      }

                      setIsHovered(false);
                    }}
                  />
                </div>
              </div>
              <div className="my-3">
                <Form>
                  <Form.Group className="mb-2">
                    <SearchableSelect
                      label="Label"
                      value={newLabelValue ?? ""}
                      onChange={(e) => {
                        setNewLabelValue(e.currentTarget.value);
                      }}
                      options={optionLabelKingPower}
                      size="sm"
                    />
                  </Form.Group>
                  {focusOcrType === "custom" && (
                    <Form.Group className="mb-2">
                      <Form.Label>Original Text</Form.Label>
                      {handleRenderDataHover().resultTextOriginal &&
                      handleRenderDataHover().resultTextOriginal!.length >
                        20 ? (
                        <Form.Control
                          size="sm"
                          type="text"
                          rows={5}
                          as="textarea"
                          disabled
                          value={handleRenderDataHover().resultTextOriginal}
                          onChange={(e) => {
                            setInputNewValue(e.currentTarget.value);
                          }}
                        />
                      ) : (
                        <Form.Control
                          size="sm"
                          type="text"
                          disabled
                          value={handleRenderDataHover().resultTextOriginal}
                          onChange={(e) => {
                            setInputNewValue(e.currentTarget.value);
                          }}
                        />
                      )}
                    </Form.Group>
                  )}
                  <Form.Group>
                    <Form.Label>
                      {focusOcrType === "custom" ? "New Text" : "Text"}
                    </Form.Label>
                    {focusOcrType === "custom" ? (
                      <>
                        {handleRenderDataHover().resultTextOriginal &&
                        handleRenderDataHover().resultTextOriginal!.length >
                          20 ? (
                          <Form.Control
                            size="sm"
                            type="text"
                            rows={5}
                            as="textarea"
                            value={inputNewValue}
                            onChange={(e) => {
                              setInputNewValue(e.currentTarget.value);
                            }}
                          />
                        ) : (
                          <>
                            {(handleRenderDataHover() as IProcessResultProps)
                              .key === KeyProcessDetailEnum.INVQTY ? (
                              <div className="d-flex gap-2">
                                <Form.Control
                                  size="sm"
                                  type="text"
                                  placeholder="1234"
                                  value={inputNewValue}
                                  onChange={(e) => {
                                    setInputNewValue(e.currentTarget.value);
                                  }}
                                />
                                <Form.Control
                                  size="sm"
                                  type="text"
                                  maxLength={3}
                                  placeholder="KGM"
                                  value={inputNewValueQty}
                                  onChange={(e) => {
                                    setInputNewValueQty(e.currentTarget.value);
                                  }}
                                />
                              </div>
                            ) : (
                              <>
                                <Form.Control
                                  size="sm"
                                  type="text"
                                  value={inputNewValue}
                                  onChange={(e) => {
                                    setInputNewValue(e.currentTarget.value);
                                  }}
                                />
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <Form.Control
                        size="sm"
                        type="text"
                        rows={5}
                        as="textarea"
                        value={inputNewValue}
                        onChange={(e) => {
                          setInputNewValue(e.currentTarget.value);
                        }}
                      />
                    )}
                  </Form.Group>
                  <div className="d-flex justify-content-center gap-3 mt-3">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setIsHovered(false);
                      }}
                      size="sm"
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="primary"
                      size="sm"
                      disabled={
                        !(inputNewValue !== "") || newLabelValue === undefined
                      }
                      onClick={() => {
                        if (focusOcrType === "ocr") {
                          if (
                            fileContext &&
                            !separateInvoice &&
                            !separatePackingList
                          ) {
                            const editDiv = document.getElementById(getDivId);
                            if (editDiv?.parentNode) {
                              editDiv.style.background = "#2689e594";
                              editDiv.style.borderColor = "#2289eadf";
                            }
                            const getResult = [...processResult];
                            const getResultOcr = [...processResultOcr];

                            getResultOcr[hoveredItemIndex].edited = true;

                            getResult.push({
                              confidence:
                                processResultOcr[hoveredItemIndex].confidence,
                              hightLightZone: {
                                boundingBox:
                                  processResultOcr[hoveredItemIndex]
                                    .hightLightZone.boundingBox,
                                page: processResultOcr[hoveredItemIndex]
                                  .hightLightZone.page,
                              },
                              id: "",
                              key: newLabelValue ?? "",
                              resultText: inputNewValue ?? "",
                              edited: true,
                              resultTextOriginal:
                                processResultOcr[hoveredItemIndex]
                                  .resultTextOriginal,
                            });

                            const divToRemove =
                              document.getElementById(getDivId);
                            if (divToRemove?.parentNode) {
                              divToRemove.parentNode.removeChild(divToRemove);
                            }

                            const pdfWidth =
                              document.querySelector(".react-pdf__Page")!
                                .clientWidth || 0;
                            const pdfHeight =
                              document.querySelector(".react-pdf__Page")!
                                .clientHeight || 0;

                            const calculateTop =
                              processResultOcr[hoveredItemIndex].hightLightZone
                                .boundingBox[0].y *
                                pdfHeight +
                              pdfHeight *
                                parseInt(
                                  processResultOcr[hoveredItemIndex]
                                    .hightLightZone.page
                                );
                            const calculateHeight =
                              (processResultOcr[hoveredItemIndex].hightLightZone
                                .boundingBox[2].y -
                                processResultOcr[hoveredItemIndex]
                                  .hightLightZone.boundingBox[1].y) *
                              pdfHeight;
                            const calculateLeft =
                              processResultOcr[hoveredItemIndex].hightLightZone
                                .boundingBox[0].x * pdfWidth;

                            const borderBox: ReactNode = (
                              <div
                                id={`resizable-box-ocr-process-result-${
                                  getResult.length - 1
                                }`}
                                className={`resizable-box-ocr cursor-pointer`}
                                style={{
                                  position: "absolute",
                                  left: `${calculateLeft}px`,
                                  top: `${calculateTop}px`,
                                  background: "#2689e594",
                                  borderColor: "#2289eadf",
                                  width: `${
                                    (processResultOcr[hoveredItemIndex]
                                      .hightLightZone.boundingBox[1].x -
                                      processResultOcr[hoveredItemIndex]
                                        .hightLightZone.boundingBox[0].x) *
                                    pdfWidth
                                  }px`,
                                  height: `${calculateHeight}px`,
                                  zIndex: "1000",
                                }}
                                onMouseEnter={(e) => {
                                  setFocusOcrType("custom");
                                  handleMouseEnter(
                                    getResult.length - 1,
                                    "custom",
                                    "invoice",
                                    getResult
                                  );
                                  setGetDivId((e.target as HTMLDivElement).id);
                                  setIsHovered(true);
                                  setHoverPosition({
                                    x: e.clientX,
                                    y: e.clientY,
                                  });
                                }}
                              ></div>
                            );

                            const container =
                              document.querySelector(".react-pdf__Page");
                            const mountPoint = document.createElement("div");
                            container?.appendChild(mountPoint);
                            const root = createRoot(mountPoint);
                            root.render(borderBox);

                            setProcessResult(getResult);
                            setProcessResultOcr(getResultOcr);
                          } else if (
                            !fileContext &&
                            separateInvoice &&
                            separatePackingList
                          ) {
                            if (focusSection === "invoice") {
                              const editDiv = document.getElementById(getDivId);
                              if (editDiv?.parentNode) {
                                editDiv.style.background = "#2689e594";
                                editDiv.style.borderColor = "#2289eadf";
                              }
                              const getResult = [...processResultInvoiceSep];
                              getResult.push({
                                confidence:
                                  processResultOcr[hoveredItemIndex].confidence,
                                hightLightZone: {
                                  boundingBox:
                                    processResultOcr[hoveredItemIndex]
                                      .hightLightZone.boundingBox,
                                  page: processResultOcr[hoveredItemIndex]
                                    .hightLightZone.page,
                                },
                                id: "",
                                key: newLabelValue ?? "",
                                resultText: inputNewValue ?? "",
                                edited: true,
                                resultTextOriginal:
                                  processResultOcr[hoveredItemIndex]
                                    .resultTextOriginal,
                              });
                              setProcessResultInvoiceSep(getResult);
                            } else {
                              const editDiv = document.getElementById(getDivId);
                              if (editDiv?.parentNode) {
                                editDiv.style.background = "#2689e594";
                                editDiv.style.borderColor = "#2289eadf";
                              }
                              const getResult = [...processResultPackingSep];
                              getResult.push({
                                confidence:
                                  processResultOcr[hoveredItemIndex].confidence,
                                hightLightZone: {
                                  boundingBox:
                                    processResultOcr[hoveredItemIndex]
                                      .hightLightZone.boundingBox,
                                  page: processResultOcr[hoveredItemIndex]
                                    .hightLightZone.page,
                                },
                                id: "",
                                key: newLabelValue ?? "",
                                resultText: inputNewValue ?? "",
                                edited: true,
                                resultTextOriginal:
                                  processResultOcr[hoveredItemIndex]
                                    .resultTextOriginal,
                              });
                              setProcessResultPackingSep(getResult);
                            }
                          }
                        } else {
                          if (inputNewValue) {
                            if (
                              fileContext &&
                              !separateInvoice &&
                              !separatePackingList
                            ) {
                              const clone = [...processResult];
                              if (
                                clone[hoveredItemIndex].key ===
                                KeyProcessDetailEnum.INVQTY
                              ) {
                                clone[hoveredItemIndex].resultText =
                                  inputNewValue + inputNewValueQty;
                                setProcessResult(clone);
                              } else {
                                clone[hoveredItemIndex].resultText =
                                  inputNewValue;
                                clone[hoveredItemIndex].key =
                                  newLabelValue ?? "";
                                setProcessResult(clone);
                              }
                            } else if (
                              !fileContext &&
                              separateInvoice &&
                              separatePackingList
                            ) {
                              if (focusSection === "invoice") {
                                const clone = [...processResultInvoiceSep];
                                if (
                                  clone[hoveredItemIndex].key ===
                                  KeyProcessDetailEnum.INVQTY
                                ) {
                                  clone[hoveredItemIndex].resultText =
                                    inputNewValue + inputNewValueQty;
                                  setProcessResultInvoiceSep(clone);
                                } else {
                                  clone[hoveredItemIndex].resultText =
                                    inputNewValue;
                                  setProcessResultInvoiceSep(clone);
                                }
                              } else {
                                const clone = [...processResultPackingSep];
                                clone[hoveredItemIndex].resultText =
                                  inputNewValue;
                                setProcessResultPackingSep(clone);
                              }
                            }
                            const editDiv = document.getElementById(getDivId);
                            if (editDiv?.parentNode) {
                              editDiv.style.background = "#2689e594";
                              editDiv.style.borderColor = "#2289eadf";
                            }
                          }
                        }
                        setIsHovered(false);
                      }}
                    >
                      {focusOcrType === "custom" ? "Update" : "Add"}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )}
      </Container>

      {errorModalComponent}

      <Modal
        show={showExportModal}
        onHide={() => {
          setShowExportModal(false);
        }}
        centered
        style={{ zIndex: "10000" }}
        className="export-modal"
      >
        <Modal.Body className="text-center">
          <div className="export-modal-header">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center gap-2">
                <div className="icon d-flex align-items-center justify-content-center">
                  <CircleCheck />
                </div>
                <div className="text-start">
                  <div className="font-bold-700">Export Success</div>
                  <div>Filetype for export</div>
                </div>
              </div>
              <X
                className="cursor-pointer"
                onClick={() => {
                  setShowExportModal(false);
                }}
              />
            </div>
            <div>
              <div
                className="csv-file-container d-flex gap-2 py-2 px-3 mt-3 cursor-pointer"
                onClick={() => {
                  if (template === TemplateDocEnum.KING_POWER) {
                    if (dataExportKingPower) {
                      if (
                        fileContext &&
                        !separateInvoice &&
                        !separatePackingList
                      ) {
                        XLSX.writeFile(
                          dataExportKingPower.dataInvoiceCsv,
                          `CHANEL ${dataExportKingPower.firstInvoiceNo}.xlsx`,
                          {
                            bookType: "xlsx",
                            type: "buffer",
                          }
                        );
                      }
                    }
                    return;
                  }
                  if (dataExport) {
                    if (
                      fileContext &&
                      !separateInvoice &&
                      !separatePackingList
                    ) {
                      XLSX.writeFile(
                        dataExport.headerCsv,
                        `${invoiceId}_Header.csv`,
                        {
                          bookType: "csv",
                          type: "buffer",
                        }
                      );
                    } else if (
                      !fileContext &&
                      separateInvoice &&
                      separatePackingList
                    ) {
                      XLSX.writeFile(
                        dataExport.headerCsv,
                        `${invoiceId}_Header.csv`,
                        {
                          bookType: "csv",
                          type: "buffer",
                        }
                      );
                    }
                  }
                }}
              >
                <img src={csvIcon} alt="csv-icon" />
                <div className="text-start">
                  <div className="font-bold-600">
                    {template === TemplateDocEnum.KING_POWER
                      ? `CHANEL ${dataExportKingPower?.firstInvoiceNo}.xlsx`
                      : `${invoiceId}_Header.csv`}
                  </div>
                  {dataExport && (
                    <div>
                      {dataExport?.headerFileSize < 1024
                        ? `${dataExport.headerFileSize} Bytes`
                        : dataExport?.headerFileSize < 1024 * 1024
                        ? `${dataExport.headerFileSize} KB`
                        : `${dataExport.headerFileSize} MB`}
                    </div>
                  )}
                </div>
              </div>
              {template !== TemplateDocEnum.KING_POWER && (
                <div
                  className="csv-file-container d-flex gap-2 py-2 px-3 mt-3 cursor-pointer"
                  onClick={() => {
                    if (dataExport) {
                      if (
                        fileContext &&
                        !separateInvoice &&
                        !separatePackingList
                      ) {
                        XLSX.writeFile(
                          dataExport.detailCsv,
                          `${invoiceId}.csv`,
                          {
                            bookType: "csv",
                            type: "buffer",
                          }
                        );
                      } else if (
                        !fileContext &&
                        separateInvoice &&
                        separatePackingList
                      ) {
                        XLSX.writeFile(
                          dataExport.detailCsv,
                          `${invoiceId}.csv`,
                          {
                            bookType: "csv",
                            type: "buffer",
                          }
                        );
                      }
                    }
                  }}
                >
                  <img src={csvIcon} alt="csv-icon" />
                  <div className="text-start">
                    <div className="font-bold-600">{`${invoiceId}.csv`}</div>
                    {dataExport && (
                      <div>
                        {dataExport?.detailFileSize < 1024
                          ? `${dataExport.detailFileSize} Bytes`
                          : dataExport?.detailFileSize < 1024 * 1024
                          ? `${dataExport.detailFileSize} KB`
                          : `${dataExport.detailFileSize} MB`}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="text-end mt-3">
              <Button
                variant="primary"
                onClick={() => {
                  setShowExportModal(false);
                  if (template === TemplateDocEnum.DELTA) {
                    navigate("/upload-file/delta", { replace: true });
                  } else if (template === TemplateDocEnum.KING_POWER) {
                    navigate("/upload-file/king-power", { replace: true });
                  }
                }}
              >
                Back to Home
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PreviewAiResult;

import { KeyProcessDetailEnum, ProcessTypeEnum } from "../../enum/processType";
import { IProcessResultProps } from "../../interface/processResult";

function findMostFrequentTwoDecimal(
  numbers: (number | undefined)[]
): number | null {
  const countMap: { [key: string]: number } = {};

  // กรองค่า undefined และแปลงตัวเลขให้เป็นทศนิยม 3 ตำแหน่ง
  for (let num of numbers) {
    if (num !== undefined) {
      // แปลงให้เหลือทศนิยม 3 ตำแหน่ง ปัดเศษลง พร้อมขยับขึ้นเล็กน้อยเพื่อป้องกันความผิดพลาด
      const twoDecimalString = (num - 0.005).toFixed(3);
      countMap[twoDecimalString] = (countMap[twoDecimalString] || 0) + 1;
    }
  }

  // หาเลขที่มีจำนวนครั้งซ้ำมากที่สุด
  let mostFrequentNumber: number | null = null;
  let maxCount = 0;
  for (let num in countMap) {
    if (countMap[num] > maxCount) {
      maxCount = countMap[num];
      mostFrequentNumber = parseFloat(num);
    }
  }
  return mostFrequentNumber;
}

function getYAtIndex(
  index: number,
  array: IProcessResultProps[]
): number | undefined {
  if (index >= 0 && index < array.length) {
    // if page > 0, y + page
    if (array[index].hightLightZone.page !== "0") {
      return (
        array[index].hightLightZone.boundingBox[0].y +
        parseInt(array[index].hightLightZone.page)
      );
    } else {
      return array[index].hightLightZone.boundingBox[0].y;
    }
  } else {
    return undefined;
  }
}

// ฟังก์ชันที่จะตรวจสอบเงื่อนไขที่ต้องการ
function getDataInBoundingBox(
  props: IProcessResultProps[],
  minY: number,
  maxY: number
): IProcessResultProps | undefined {
  if (props === undefined) {
    return undefined;
  }
  // แปลง page จาก string เป็น number เพื่อทำการเปรียบเทียบ
  let targetData = props.find((item, index) => {
    // ตรวจสอบว่า page ตรงกันและค่า y ของ boundingBox[0] อยู่ในช่วงที่กำหนดหรือไม่
    if (item.hightLightZone.boundingBox.length > 0) {
      const boundingBoxY = getYAtIndex(index, props);
      if (boundingBoxY === undefined) {
        return false;
      }
      if (boundingBoxY >= minY && boundingBoxY <= maxY) {
        return true;
      } else {
        return false;
      }
    }
  });

  return targetData;
}
export interface IGroupByRow {
  minY: number;
  maxY: number;
  data: Partial<Record<KeyProcessDetailEnum, IProcessResultProps | undefined>>; // ทำให้ key ทั้งหมดเป็น optional;
}

export const groupingByRow = (
  data: IProcessResultProps[],
  processType: ProcessTypeEnum
): IGroupByRow[] => {
  if (processType === ProcessTypeEnum.INVOICE) {
    return groupByRow(
      data,
      [
        KeyProcessDetailEnum.INVQTY,
        KeyProcessDetailEnum.PRICE,
        KeyProcessDetailEnum.AMOUNT,
        KeyProcessDetailEnum.INVCURR,
      ],
      [
        KeyProcessDetailEnum.INVQTY,
        KeyProcessDetailEnum.PRICE,
        KeyProcessDetailEnum.AMOUNT,
        KeyProcessDetailEnum.PRODUCT_ID,
        KeyProcessDetailEnum.DES1,
        KeyProcessDetailEnum.INVCURR,
      ]
    );
  } else if (processType === ProcessTypeEnum.PACKING_LIST) {
    return groupByRow(
      data,
      [KeyProcessDetailEnum.WEIGHT],
      [KeyProcessDetailEnum.WEIGHT]
    );
  } else {
    throw new Error("Invalid process type");
  }
};

export const groupingByRowKingPower = (
  data: IProcessResultProps[]
): IGroupByRow[] => {
  return groupByRow(
    data,
    [
      KeyProcessDetailEnum.KING_POWER_INVOICE_QTY,
      KeyProcessDetailEnum.KING_POWER_INVOICE_UNIT_PRICE,
      KeyProcessDetailEnum.KING_POWER_INVOICE_MADE_IN,
    ],
    [
      KeyProcessDetailEnum.KING_POWER_INVOICE_ITEM,
      KeyProcessDetailEnum.KING_POWER_INVOICE_DESCRIPTION,
      KeyProcessDetailEnum.KING_POWER_INVOICE_QTY,
      KeyProcessDetailEnum.KING_POWER_INVOICE_UNIT_PRICE,
      KeyProcessDetailEnum.KING_POWER_INVOICE_MADE_IN,
    ]
  );
};

// ฟังก์ชันสำหรับจัดกลุ่มข้อมูล invoice โดยรับ key แบบ dynamic เพื่อหา minY, maxY และจัดกลุ่มข้อมูล
export const groupByRow = (
  data: IProcessResultProps[], // ข้อมูลที่ต้องประมวลผล
  minMaxKeys: KeyProcessDetailEnum[], // กลุ่ม KeyProcessDetailEnum ที่ใช้หา minY และ maxY
  groupByKeys: KeyProcessDetailEnum[] // กลุ่ม KeyProcessDetailEnum ที่ใช้จัดกลุ่มข้อมูลในแต่ละแถว
): IGroupByRow[] => {
  // ฟังก์ชันย่อยที่กรองข้อมูลตาม key ที่กำหนด
  const filterDataByKey = (key: KeyProcessDetailEnum) =>
    data.filter((item) => item.key === key);

  // สร้าง Object เพื่อเก็บข้อมูลที่จัดกลุ่มตาม key แบบ dynamic
  let groupedData: { [key: string]: IProcessResultProps[] } = {};
  groupByKeys.forEach((key) => {
    groupedData[key] = filterDataByKey(key);
  });

  // สร้าง Array ที่เก็บข้อมูลสำหรับการหา minY และ maxY
  let minMaxData: IProcessResultProps[][] = minMaxKeys.map((key) =>
    filterDataByKey(key)
  );

  // หา length สูงสุดของ array ในแต่ละกลุ่มเพื่อใช้ในการวนลูป
  const maxLength = Math.max(
    ...Object.values(groupedData).map((arr) => arr.length)
  );

  // เตรียม array สำหรับเก็บผลลัพธ์ และเก็บจำนวน undefined สำหรับแต่ละ key
  let resultData: IGroupByRow[] = [];
  let undefinedCount: { [key: string]: number } = {};

  // เริ่มต้นนับ undefined สำหรับแต่ละ group key
  groupByKeys.forEach((key) => {
    undefinedCount[key] = 0;
  });

  // วนลูปตามจำนวนแถวสูงสุด (maxLength)
  for (let i = 0; i < maxLength; i++) {
    // หา minY จากกลุ่มข้อมูล minMaxKeys
    let minY = findMostFrequentTwoDecimal(
      minMaxData.map((arr) => getYAtIndex(i, arr))
    );

    if (minY === null || minY === undefined) {
      throw new Error("minY is null or undefined");
    }

    // หา maxY จากกลุ่มข้อมูล minMaxKeys
    let maxY = findMostFrequentTwoDecimal(
      minMaxData.map((arr) => getYAtIndex(i + 1, arr))
    );

    // ถ้า maxY ไม่มีค่า ให้ใช้ค่า minY และปัดขึ้น
    if (maxY === null || maxY === undefined) {
      maxY = Math.ceil(minY);
    }

    // สร้างข้อมูลแถวใหม่ที่จะถูกจัดกลุ่ม
    let rowData: IGroupByRow = {
      minY: minY,
      maxY: maxY,
      data: {},
    };

    // จัดกลุ่มข้อมูลตาม groupByKeys โดยการตรวจสอบในช่วง minY ถึง maxY
    groupByKeys.forEach((key) => {
      // ตรวจสอบว่า minY และ maxY ไม่ใช่ null
      if (minY !== null && maxY !== null) {
        // ตรวจสอบข้อมูลที่อยู่ใน bounding box ตาม minY และ maxY
        const dataInBox = getDataInBoundingBox(groupedData[key], minY, maxY);

        // เพิ่มข้อมูลเข้าไปใน rowData.data
        rowData.data[key] = dataInBox;

        // นับจำนวน undefined ถ้าข้อมูลในช่วงนี้ไม่มีค่า
        if (dataInBox === undefined && i < maxLength - 1) {
          undefinedCount[key]++;
        }
      } else {
        // กรณีที่ minY หรือ maxY เป็น null คุณอาจต้องการข้ามการประมวลผล หรือจัดการกรณีนี้ตามที่คุณต้องการ
        console.warn(`Skipping key ${key} due to invalid minY or maxY`);
      }
    });

    // เพิ่มแถวที่สร้างใหม่ลงในผลลัพธ์
    resultData.push(rowData);
  }

  // แสดงผล undefined count สำหรับตรวจสอบ
  console.debug("undefinedCount", undefinedCount);

  // ส่งผลลัพธ์กลับ
  return resultData;
};

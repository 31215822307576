import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginPage from "./pages/verifyPage/verifyPage";
import "./style/app.scss";
import UploadFile from "./pages/upload/uploadFile";
import { FileProvider } from "./context/fileContext";
import PreviewFilePage from "./pages/previewFilePage/previewFilePage";
import PreviewAiResult from "./pages/previewAiResult/previewAiResult";
import { ErrorModalProvider } from "./context/errorModalContext/errorModalContext";
import { TemplateDocEnum } from "./enum/templateType";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "./layout";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    element: <Layout />,
    children: [
      {
        path: "/upload-file/delta",
        element: <UploadFile template={TemplateDocEnum.DELTA} />,
      },
      {
        path: "/upload-file/king-power",
        element: <UploadFile template={TemplateDocEnum.KING_POWER} />,
      },
      {
        path: "/preview-file",
        element: <PreviewFilePage />,
      },
      {
        path: "/preview-ai-result",
        element: <PreviewAiResult />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ErrorModalProvider>
    <FileProvider>
      <RouterProvider router={router} />
    </FileProvider>
  </ErrorModalProvider>
);

reportWebVitals();

import { KeyProcessDetailEnum } from "../enum/processType";

export const optionLabelKingPower = [
  {
    label: KeyProcessDetailEnum.KING_POWER_INVOICE_CUSTOMER_REF,
    value: KeyProcessDetailEnum.KING_POWER_INVOICE_CUSTOMER_REF,
  },
  {
    label: KeyProcessDetailEnum.KING_POWER_INVOICE_DESCRIPTION,
    value: KeyProcessDetailEnum.KING_POWER_INVOICE_DESCRIPTION,
  },
  {
    label: KeyProcessDetailEnum.KING_POWER_INVOICE_INVOICE_NO,
    value: KeyProcessDetailEnum.KING_POWER_INVOICE_INVOICE_NO,
  },
  {
    label: KeyProcessDetailEnum.KING_POWER_INVOICE_ITEM,
    value: KeyProcessDetailEnum.KING_POWER_INVOICE_ITEM,
  },
  {
    label: KeyProcessDetailEnum.KING_POWER_INVOICE_MADE_IN,
    value: KeyProcessDetailEnum.KING_POWER_INVOICE_MADE_IN,
  },
  {
    label: KeyProcessDetailEnum.KING_POWER_INVOICE_QTY,
    value: KeyProcessDetailEnum.KING_POWER_INVOICE_QTY,
  },
  {
    label: KeyProcessDetailEnum.KING_POWER_INVOICE_UNIT_PRICE,
    value: KeyProcessDetailEnum.KING_POWER_INVOICE_UNIT_PRICE,
  },
];

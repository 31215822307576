import {
  KeyProcessDetailEnum,
  KeyProcessHeaderEnum,
} from "../enum/processType";
import { IDataDetailMap, IDataHeaderMap } from "../interface/mapData";
import { IProcessResultProps } from "../interface/processResult";
import * as XLSX from "xlsx";
import { convertDateFormat } from "./convertDateFormat";
import { getCountryInitials } from "./getNameCountry";
import { separateInvQty } from "./separateInvQty";

export interface IExportCsvReturn {
  headerCsv: XLSX.WorkBook;
  detailCsv: XLSX.WorkBook;
  headerFileSize: number;
  detailFileSize: number;
}

const normalizeString = (str: string): string => {
  return str
    .replace(/[АΑΔΑ]/g, "A")
    .replace(/[ВΒ]/g, "B")
    .replace(/[СϹΓϹ]/g, "C")
    .replace(/[РЕΡР]/g, "P")
    .replace(/[ЕΕЕ]/g, "E")
    .replace(/[ΘΕЕ]/g, "F")
    .replace(/[ΟО]/g, "O")
    .replace(/[ΝⲚⲚΙ]/g, "N")
    .replace(/[ΗΗ]/g, "H")
    .replace(/[ІΙ]/g, "I")
    .replace(/[ЈЈ]/g, "J")
    .replace(/[КΚ]/g, "K")
    .replace(/[ГⲄᏀԌ]/g, "G")
    .replace(/[ⲘΜ]/g, "M")
    .replace(/[ТΤԎᎢ]/g, "T")
    .replace(/[ЅЅ]/g, "S")
    .replace(/[ЦᏟᏕⲤ]/g, "C")
    .replace(/[ᏙᎠⅤ]/g, "V")
    .replace(/[ЏѠ]/g, "W")
    .replace(/[ΧΧ]/g, "X")
    .replace(/[ΥҮ]/g, "Y")
    .replace(/[Ζ]/g, "Z")
    .replace(/[аα]/g, "a")
    .replace(/[ъЬЬ]/g, "b")
    .replace(/[сς]/g, "c")
    .replace(/[ԁⅾ]/g, "d")
    .replace(/[еε]/g, "e")
    .replace(/[𝖿]/g, "f")
    .replace(/[ɡ]/g, "g")
    .replace(/[һ]/g, "h")
    .replace(/[іі]/g, "i")
    .replace(/[ј]/g, "j")
    .replace(/[κк]/g, "k")
    .replace(/[ӏ]/g, "l")
    .replace(/[ⅿ]/g, "m")
    .replace(/[ոռ]/g, "n")
    .replace(/[оο]/g, "o")
    .replace(/[р]/g, "p")
    .replace(/[ԛ]/g, "q")
    .replace(/[гᴦ]/g, "r")
    .replace(/[ѕ]/g, "s")
    .replace(/[τ]/g, "t")
    .replace(/[ս]/g, "u")
    .replace(/[ν]/g, "v")
    .replace(/[ѡ]/g, "w")
    .replace(/[х]/g, "x")
    .replace(/[у]/g, "y")
    .replace(/[ᴢ]/g, "z");
};

export function exportDataToCsv(
  processInvoiceResult: IProcessResultProps[],
  processPackingListResult?: IProcessResultProps[]
): IExportCsvReturn {
  const headersData: IDataHeaderMap[] = [
    {
      CMCODE: "",
      INVNO: "",
      INVDATE: "",
      JOBCODE: "",
      SOLDCODE: "",
      SOLDATT1: "",
      COMLV: "",
      FSELLER_M: "",
      FSELLER2_M: "",
      FSELLAD1_C: "",
      FSELLAD2_C: "",
      FSELLAD3_C: "",
      FSELLAD4_C: "",
      SELLCTRY: "",
      SELLPOST: "",
      SELLMAIL: "",
      PURCTRY: "",
      DESCTRY: "",
      EXIMDATE: "",
      TERM: "",
      INVCURR: "",
      INVTOT: "",
      FORCURR: "",
      FORCH: "",
      FRICURR: "",
      FRICH: "",
      INSCURR: "",
      INSCH: "",
      LOAD_PORT: "",
      RELE_PORT: "",
      VESNAME1: "",
      TOTPACK: "",
      TOTPACKCHR: "",
      TOTNETW: "",
      TOTGROW: "",
      PACKU: "",
      NETWU: "",
      GROWU: "",
      FBL_N: "",
      PAYTERM: "",
      DELITERM: "",
      MAWB: "",
      SHIPMARK: "",
      PONO: "",
      PACKCHGCURR: "",
      PACKCHG: "",
      FORINCHGCURR: "",
      FORINCHG: "",
      LANDINGCHGCURR: "",
      LANDINGCHG: "",
      OTHCHGCURR: "",
      OTHCHG: "",
      DISCOUNT: "",
      DISC_PERC: "",
      REFNOCOMACC: "",
      USESIGNNET: "",
      EXPORTTAX_INCEN_ID: "",
      TRAN_MODE: "",
      VOY: "",
      CARGOPACKTYPE: "",
      DOCTYPE: "",
      CCARD: "",
      CSCARD: "",
    },
  ];

  const detailsData: IDataDetailMap[] = [];

  const productIdList = processInvoiceResult.filter(
    (value) => value.key === KeyProcessDetailEnum.PRODUCT_ID
  );
  let weightList: IProcessResultProps[] = [];
  if (processPackingListResult) {
    weightList = processPackingListResult.filter(
      (value) => value.key === KeyProcessDetailEnum.WEIGHT
    );
  }

  let weightTuList: IProcessResultProps[] = [];
  if (processPackingListResult) {
    weightTuList = processPackingListResult.filter(
      (value) => value.key === KeyProcessDetailEnum.WEIGHTU
    );
  }

  const qtyList = processInvoiceResult.filter(
    (value) => value.key === KeyProcessDetailEnum.QTY
  );
  const qtyuList = processInvoiceResult.filter(
    (value) => value.key === KeyProcessDetailEnum.QTYU
  );
  const incurrList = processInvoiceResult.filter(
    (value) => value.key === KeyProcessDetailEnum.INVCURR
  );
  const priceList = processInvoiceResult.filter(
    (value) => value.key === KeyProcessDetailEnum.PRICE
  );
  const invQtyList = processInvoiceResult.filter(
    (value) => value.key === KeyProcessDetailEnum.INVQTY
  );
  const invQtyuList = processInvoiceResult.filter(
    (value) => value.key === KeyProcessDetailEnum.INVQTYU
  );
  const amountList = processInvoiceResult.filter(
    (value) => value.key === KeyProcessDetailEnum.AMOUNT
  );

  const productDescription = processInvoiceResult?.filter(
    (value) => value.key === KeyProcessDetailEnum.DES1
  );

  processInvoiceResult.forEach((result) => {
    if (KeyProcessHeaderEnum.INVNO === result.key) {
      headersData[0].INVNO = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.INVDATE === result.key) {
      headersData[0].INVDATE = convertDateFormat(result.resultText) ?? "";
    } else if (KeyProcessHeaderEnum.JOBCODE === result.key) {
      headersData[0].JOBCODE = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.SOLDCODE === result.key) {
      headersData[0].SOLDCODE = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.SOLDATT1 === result.key) {
      headersData[0].SOLDATT1 = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.COMLV === result.key) {
      headersData[0].COMLV = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.FSELLER_M === result.key) {
      headersData[0].FSELLER_M = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.FSELLER2_M === result.key) {
      headersData[0].FSELLER2_M = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.FSELLAD1_C === result.key) {
      headersData[0].FSELLAD1_C = result.resultText
        ? result.resultText.slice(0, 70)
        : "";
    } else if (KeyProcessHeaderEnum.FSELLAD2_C === result.key) {
      headersData[0].FSELLAD2_C = result.resultText
        ? result.resultText.slice(0, 35)
        : "";
    } else if (KeyProcessHeaderEnum.FSELLAD3_C === result.key) {
      headersData[0].FSELLAD3_C = result.resultText
        ? result.resultText.slice(0, 35)
        : "";
    } else if (KeyProcessHeaderEnum.FSELLAD4_C === result.key) {
      headersData[0].FSELLAD4_C = result.resultText
        ? result.resultText.slice(0, 35)
        : "";
    } else if (KeyProcessHeaderEnum.SELLCTRY === result.key) {
      headersData[0].SELLCTRY = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.SELLPOST === result.key) {
      headersData[0].SELLPOST = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.SELLMAIL === result.key) {
      headersData[0].SELLMAIL = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.PURCTRY === result.key) {
      headersData[0].PURCTRY = result.resultText
        ? getCountryInitials(result.resultText)
        : "";
    } else if (KeyProcessHeaderEnum.DESCTRY === result.key) {
      headersData[0].DESCTRY = result.resultText
        ? getCountryInitials(result.resultText)
        : "";
    } else if (KeyProcessHeaderEnum.EXIMDATE === result.key) {
      headersData[0].EXIMDATE = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.TERM === result.key) {
      headersData[0].TERM = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.INVCURR === result.key) {
      headersData[0].INVCURR = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.INVTOT === result.key) {
      headersData[0].INVTOT = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.FORCURR === result.key) {
      headersData[0].FORCURR = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.FORCH === result.key) {
      headersData[0].FORCH = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.FRICURR === result.key) {
      headersData[0].FRICURR = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.FRICH === result.key) {
      headersData[0].FRICH = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.INSCURR === result.key) {
      headersData[0].INSCURR = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.INSCH === result.key) {
      headersData[0].INSCH = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.LOAD_PORT === result.key) {
      headersData[0].LOAD_PORT = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.RELE_PORT === result.key) {
      headersData[0].RELE_PORT = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.VESNAME1 === result.key) {
      headersData[0].VESNAME1 = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.TOTNETW === result.key) {
      headersData[0].TOTNETW =
        separateInvQty(
          result.resultText.includes("\n")
            ? result.resultText.split("\n").join("") ?? ""
            : result.resultText ?? ""
        ).number ?? "";
      headersData[0].NETWU =
        separateInvQty(
          result.resultText.includes("\n")
            ? result.resultText.split("\n").join("") ?? ""
            : result.resultText ?? ""
        ).characters ?? "";
    } else if (KeyProcessHeaderEnum.TOTGROW === result.key) {
      headersData[0].TOTGROW =
        separateInvQty(
          result.resultText.includes("\n")
            ? result.resultText.split("\n").join("") ?? ""
            : result.resultText ?? ""
        ).number ?? "";
      headersData[0].GROWU =
        separateInvQty(
          result.resultText.includes("\n")
            ? result.resultText.split("\n").join("") ?? ""
            : result.resultText ?? ""
        ).characters ?? "";
    } else if (KeyProcessHeaderEnum.NETWU === result.key) {
      headersData[0].NETWU = separateInvQty(result.resultText).characters;
    } else if (KeyProcessHeaderEnum.GROWU === result.key) {
      headersData[0].GROWU = separateInvQty(result.resultText).characters;
    } else if (KeyProcessHeaderEnum.FBL_N === result.key) {
      headersData[0].FBL_N = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.PAYTERM === result.key) {
      headersData[0].PAYTERM = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.DELITERM === result.key) {
      headersData[0].DELITERM = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.MAWB === result.key) {
      headersData[0].MAWB = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.SHIPMARK === result.key) {
      headersData[0].SHIPMARK = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.PONO === result.key) {
      headersData[0].PONO = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.PACKCHGCURR === result.key) {
      headersData[0].PACKCHGCURR = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.PACKCHG === result.key) {
      headersData[0].PACKCHG = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.FORINCHGCURR === result.key) {
      headersData[0].FORINCHGCURR = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.FORINCHG === result.key) {
      headersData[0].FORINCHG = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.LANDINGCHGCURR === result.key) {
      headersData[0].LANDINGCHGCURR = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.LANDINGCHG === result.key) {
      headersData[0].LANDINGCHG = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.OTHCHGCURR === result.key) {
      headersData[0].OTHCHGCURR = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.OTHCHG === result.key) {
      headersData[0].OTHCHG = result.resultText ?? "";
    } else if (KeyProcessHeaderEnum.TRAN_MODE === result.key) {
      headersData[0].TRAN_MODE = result.resultText ?? "";
    }
  });

  let invNo = processInvoiceResult.find(
    (value) => value.key === KeyProcessHeaderEnum.INVNO
  );
  let invDate = processInvoiceResult.find(
    (value) => value.key === KeyProcessHeaderEnum.INVDATE
  );

  processPackingListResult?.forEach((result) => {
    if (KeyProcessHeaderEnum.TOTPACK === result.key) {
      const totPack = result.resultText.split(" ");
      headersData[0].TOTPACK = totPack[0] ?? "";
      headersData[0].TOTPACKCHR = totPack[1] ?? "";
      headersData[0].PACKU = totPack[1] ?? "";
    }
  });

  productIdList.forEach((result, index) => {
    detailsData.push({
      INVNO: invNo?.resultText ?? "",
      INVDATE: invDate?.resultText ?? "",
      PRODUCT_ID: result.resultText ?? "",
      FOC:
        processInvoiceResult.find(
          (value) => value.key === KeyProcessDetailEnum.FOC
        ) !== undefined
          ? processInvoiceResult.find(
              (value) => value.key === KeyProcessDetailEnum.FOC
            )?.resultText
          : "N",
      ORICTRY: processInvoiceResult.find(
        (value) => value.key === KeyProcessDetailEnum.ORICTRY
      )?.resultText
        ? getCountryInitials(
            processInvoiceResult.find(
              (value) => value.key === KeyProcessDetailEnum.ORICTRY
            )?.resultText ?? ""
          )
        : "",
      TYPECAL: "",
      TARCL: "",
      TARST: "",
      TARSE: "",
      TARST_U: "",
      RTCPRD: "",
      PRDYEAR: "",
      WEIGHT: weightList[index] ? weightList[index].resultText : "",
      WEIGHTU: weightTuList[0] ? weightTuList[0].resultText : "",
      QTY: invQtyList[index]
        ? separateInvQty(invQtyList[index].resultText).number
        : "",
      QTYU: invQtyList[index]
        ? separateInvQty(invQtyList[index].resultText).characters
        : "",
      INVCURR: incurrList[index] ? incurrList[index].resultText : "",
      PRICE: priceList[index] ? priceList[index].resultText : "",
      INVQTY: invQtyList[index]
        ? separateInvQty(invQtyList[index].resultText).number
        : "",
      INVQTYU: invQtyList[index]
        ? separateInvQty(invQtyList[index].resultText).characters
        : "",
      AMOUNT: amountList[index] ? amountList[index].resultText : "",
      BRAND: "",
      PURCTRY: processInvoiceResult.find(
        (value) => value.key === KeyProcessDetailEnum.PURCTRY
      )?.resultText
        ? getCountryInitials(
            processInvoiceResult.find(
              (value) => value.key === KeyProcessDetailEnum.PURCTRY
            )?.resultText ?? ""
          )
        : "",
      PROATT1: "",
      PROATT2: "",
      FORCURR: "",
      FORF: "",
      FRICURR: "",
      FRI: "",
      INSCURR: "",
      INS: "",
      DES1: "",
      DES2: "",
      DES3: "",
      DES4: "",
      THDES1: "",
      THDES2: "",
      THDES3: "",
      THDES4: "",
      EXEMPTDUTY: "",
      PRIVILEGE: "",
      TERM:
        processInvoiceResult.find(
          (value) => value.key === KeyProcessDetailEnum.TERM
        ) !== undefined
          ? processInvoiceResult.find(
              (value) => value.key === KeyProcessDetailEnum.TERM
            )?.resultText
          : "",
      MR1: headersData[0].SHIPMARK,
      MR2: "",
      MR3: "",
      MR4: "",
      MR5: "",
      MR6: "",
      NUMPKG: "",
      UNITPKG: "",
      COMPEN: "",
      BOIUSE: "",
      BOI: "",
      BOND: "",
      BONDFML: "",
      M19: "",
      FORMULAR_C: "",
      FORM19TRANS: "",
      MODEL_VER: "",
      MODEL_COMPTAXNO: "",
      UNDG: "",
      REIMP: "",
      REEXP: "",
      FREEZONE: "",
      EPZ: "",
      IMPORTTAX_INCEN_ID: "",
      IMPDECNO: "",
      IMPDECL: "",
      PERMIT_N: "",
      PERMIT_ISSUE: "",
      PERMIT_DATE: "",
      PERMIT_N2: "",
      PERMIT_ISSUE2: "",
      PERMIT_DATE2: "",
      PERMIT_N3: "",
      PERMIT_ISSUE3: "",
      PERMIT_DATE3: "",
      REMARK: "",
      ASSESSVALUE: "",
      ARG_TARIFF: "",
      ARG_TARSE: "",
      ORIGIN_CRITERIA: "",
      SELFCERT: "",
      CERT_EXPNO: "",
      NATURETRAN: "",
      FORMULA_BOI: "",
      SELFCERT_NOT_INVNO: "",
      INCRCURR: "",
      INCREXCH: "",
      PONO_DET: "",
    });
  });

  detailsData.forEach((element, index) => {
    detailsData[index].DES1 = productDescription[index]
      ? productDescription[index].resultText
      : "";
  });

  // Convert the array of objects to a worksheet
  const headersSheet = XLSX.utils.json_to_sheet(headersData);
  const detailsSheet = XLSX.utils.json_to_sheet(detailsData);

  // Create a new workbook
  const wb = XLSX.utils.book_new();

  // Append worksheets to the workbook
  XLSX.utils.book_append_sheet(wb, headersSheet, "Headers");
  XLSX.utils.book_append_sheet(wb, detailsSheet, "Details");

  // Write the workbook to a file and trigger the download
  const headerCsv = XLSX.utils.book_new();
  const detailCsv = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(headerCsv, headersSheet, "Headers");
  XLSX.utils.book_append_sheet(detailCsv, detailsSheet, "Details");

  // Convert the workbook to a binary string
  const headerCsvBinary = XLSX.write(headerCsv, { type: "binary" });
  const detailCsvBinary = XLSX.write(detailCsv, { type: "binary" });

  // Calculate the size of the binary string
  const headerCsvSize = headerCsvBinary.length;
  const detailCsvSize = detailCsvBinary.length;

  return {
    headerCsv: headerCsv,
    detailCsv: detailCsv,
    detailFileSize: detailCsvSize,
    headerFileSize: headerCsvSize,
  };
}

"use client";

import { useEffect, useState } from "react";
import { Button, Container, Form, Row } from "react-bootstrap";
import { useFileContext } from "../../context/fileContext";
import "./previewFilePage.scss";
import { Document, Page, pdfjs } from "react-pdf";
import React from "react";
import { useNavigate } from "react-router-dom";
import { DisplayFileModeEnum } from "../../enum/displayFileMode";
import { PDFDocument } from "pdf-lib";
import { useErrorContext } from "../../context/errorModalContext/errorModalContext";
import { TemplateDocEnum } from "../../enum/templateType";

// pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdn.jsdelivr.net/npm/pdfjs-dist@4.6.82/+esm`;

pdfjs.GlobalWorkerOptions.workerSrc =
  await `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export interface IKeywordList {
  keyword: string;
  startPage: number;
  totalPage?: number;
}

const PreviewFilePage: React.FC = () => {
  const navigate = useNavigate();

  const PdfRef = React.createRef<Document>();
  const pdfRefSepInvoice = React.createRef<Document>();
  const pdfRefSepPackingList = React.createRef<Document>();

  const {
    fileContext,
    displayMode,
    separateInvoice,
    separatePackingList,
    setDisplayFileMode,
    setSepInvoiceFile,
    setContextFile,
    setSepPackListFile,
  } = useFileContext();

  const { errorModalComponent, showErrorModal } = useErrorContext();

  const [startPageInvoice, setStartPageInvoice] = useState<number | undefined>(
    undefined
  );

  const [endPageInvoice, setEndPageInvoice] = useState<number | undefined>(
    undefined
  );

  const [startPagePackingList, setStartPagePackingList] = useState<
    number | undefined
  >(undefined);

  const [endPagePackingList, setEndPagePackingList] = useState<
    number | undefined
  >(undefined);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const onDocumentSepInvoiceLoadSuccess = ({
    numPages,
  }: {
    numPages: number;
  }) => {
    setNumPagesInvoice(numPages);
  };

  const onDocumentSepPackingLoadSuccess = ({
    numPages,
  }: {
    numPages: number;
  }) => {
    setNumPagesPackingList(numPages);
  };

  const [numPages, setNumPages] = useState<number>(0);
  const [numPagesInvoice, setNumPagesInvoice] = useState<number>(0);
  const [numPagesPackingList, setNumPagesPackingList] = useState<number>(0);

  const [getKeyword, setGetKeyword] = useState<IKeywordList[]>([]);
  const [getKeywordSepInvoice, setGetKeywordSepInvoice] = useState<
    IKeywordList[]
  >([]);
  const [getKeywordSepPacking, setGetKeywordSepPacking] = useState<
    IKeywordList[]
  >([]);

  const [totalPage, setTotalPage] = useState<number>(0);

  const [previewMode, setPreviewMode] = useState<"invoice" | "packing-list">(
    "invoice"
  );

  const [LoadDocSuccess, setLoadDocSuccess] = useState<boolean>(false);

  const [uploadMode, setUploadMode] = useState<"single" | "multiple">("single");

  const [template, setTemplate] = useState<TemplateDocEnum | null>();

  useEffect(() => {
    const userData: string | null = localStorage.getItem("user-key");
    const userTemplate: string | null = localStorage.getItem("user-template");
    setTemplate(userTemplate as TemplateDocEnum);
    if (userData === null) {
      navigate("/", { replace: true });
      return;
    } else if ((userData as TemplateDocEnum) !== userTemplate) {
      navigate("/", { replace: true });
      return;
    }
    if (userTemplate === undefined) {
      navigate("/", { replace: true });
      return;
    }
    if (!fileContext && !separateInvoice && !separatePackingList) {
      navigate("/", { replace: true });
      return;
    } else {
      handleSetStartPage();
    }
  }, []);

  useEffect(() => {
    if (fileContext && !separateInvoice && !separatePackingList) {
      if (getKeyword.length === 0 && LoadDocSuccess) {
        showErrorModal(
          "Can't define page!",
          "Please check your document File or you can define page manually.",
          "warning"
        );
      }
      const getInvoice = getKeyword.find((e) =>
        e.keyword.toLowerCase().includes("invoice".toLowerCase())
      );

      const getPackingList = getKeyword.find((e) =>
        e.keyword.toLowerCase().includes("packing list".toLowerCase())
      );

      if (getInvoice) {
        setStartPageInvoice(getInvoice.startPage);
        if (getPackingList === undefined) {
          setEndPageInvoice(totalPage);
        }
      }

      if (getPackingList) {
        setStartPagePackingList(getPackingList.startPage);
        setEndPagePackingList(totalPage);
        setEndPageInvoice(getPackingList.startPage - 1);
      }

      if (getInvoice && getPackingList) {
        setDisplayFileMode(DisplayFileModeEnum.INVOICE_PACKING_LIST);
        separatePDF(
          getInvoice.startPage,
          getPackingList.startPage - 1,
          getPackingList.startPage,
          totalPage,
          getKeyword.length === 0
        );
      } else if (getInvoice && !getPackingList) {
        setDisplayFileMode(DisplayFileModeEnum.INVOICE);
      }
      setUploadMode("single");
    } else if (!fileContext && separateInvoice && separatePackingList) {
      const getInvoice = getKeywordSepInvoice.find((e) =>
        e.keyword.toLowerCase().includes("invoice".toLowerCase())
      );
      const getPackingList = getKeywordSepPacking.find((e) =>
        e.keyword.toLowerCase().includes("packing list".toLowerCase())
      );

      if (getInvoice) {
        setStartPageInvoice(getInvoice.startPage);
        setEndPageInvoice(getInvoice.totalPage);
      }

      if (getPackingList) {
        setStartPagePackingList(getPackingList.startPage);
        setEndPagePackingList(getPackingList.totalPage);
      }
      setUploadMode("multiple");
    }
  }, [getKeyword, getKeywordSepInvoice, getKeywordSepPacking]);

  const separatePDF = async (
    startPageInvoice: number,
    endPageInvoice: number,
    startPagePacking: number,
    endPagePacking: number,
    fakePdfMode: boolean
  ) => {
    if (!fileContext) return;

    const reader = new FileReader();
    reader.onload = async () => {
      const pdfDoc = await PDFDocument.load(reader.result!);

      const invoiceDoc = await PDFDocument.create();
      const packingListDoc = await PDFDocument.create();

      for (let i = startPageInvoice - 1; i < endPageInvoice; i++) {
        const [copiedPage] = await invoiceDoc.copyPages(pdfDoc, [i]);
        invoiceDoc.addPage(copiedPage);
      }

      for (let i = startPagePacking - 1; i < endPagePacking; i++) {
        const [copiedPage] = await packingListDoc.copyPages(pdfDoc, [i]);
        packingListDoc.addPage(copiedPage);
      }

      setNumPagesInvoice(invoiceDoc.getPageCount());
      setNumPagesPackingList(packingListDoc.getPageCount());

      const invoiceBytes = await invoiceDoc.save();
      const packingListBytes = await packingListDoc.save();
      const invoiceBlob = new Blob([invoiceBytes], { type: "application/pdf" });
      const packingListBlob = new Blob([packingListBytes], {
        type: "application/pdf",
      });
      const newInvoiceFile = new File([invoiceBlob], `${fileContext.name}`, {
        type: "application/pdf",
      });
      const newPackListFile = new File(
        [packingListBlob],
        `${fileContext.name}`,
        {
          type: "application/pdf",
        }
      );

      setSepInvoiceFile(newInvoiceFile);
      setSepPackListFile(newPackListFile);
      setContextFile(undefined);
    };
    reader.readAsArrayBuffer(fileContext);
  };

  // TODO : waiting for implement to handle fake pdf
  //   const extractImagesFromPDF = async (
  //     pdf: pdfjsLib.PDFDocumentProxy,
  //     pageNum: number
  //   ) => {
  //     const page = await pdf.getPage(pageNum);
  //     const ops = await page.getOperatorList();
  //     const images: string[] = [];

  //     for (let i = 0; i < ops.fnArray.length; i++) {
  //       if (ops.fnArray[i] === pdfjsLib.OPS.paintImageXObject) {
  //         const objId = ops.argsArray[i][0];
  //         const img = await page.objs.get(objId);
  //         const canvas = document.createElement("canvas");
  //         canvas.width = img.width;
  //         canvas.height = img.height;
  //         const ctx = canvas.getContext("2d");
  //         if (ctx) {
  //           ctx.putImageData(img.data, 0, 0);
  //           images.push(canvas.toDataURL());
  //         }
  //       }
  //     }

  //     return images;
  //   };

  //   const performOCR = async (imageUrl: string) => {
  //     const result = await Tesseract.recognize(imageUrl, "eng");
  //     return result.data.text;
  //   };

  const handleSetStartPage = async () => {
    if (fileContext && !separateInvoice && !separatePackingList) {
      if (!fileContext) return;

      const reader = new FileReader();
      reader.readAsArrayBuffer(fileContext!);

      reader.onload = async (event) => {
        if (event.target?.result) {
          const typedarray = new Uint8Array(event.target.result as ArrayBuffer);
          const pdf = await pdfjs.getDocument(typedarray).promise;

          const occurrences: IKeywordList[] = [];

          setTotalPage(pdf.numPages);

          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const textContent = await page.getTextContent();
            textContent.items.forEach((item) => {
              if ("str" in item) {
                if (
                  ["invoice", "packing list"].some(
                    (keyword) =>
                      item.str.toLowerCase().includes(keyword.toLowerCase()) &&
                      item.str.length === keyword.length
                  )
                ) {
                  if (
                    !occurrences.find(
                      (e) => e.keyword.toLowerCase() === item.str.toLowerCase()
                    )
                  ) {
                    occurrences.push({
                      keyword: item.str,
                      startPage: i,
                    });
                  }
                }

                return item.str;
              }
            });
          }

          setGetKeyword(occurrences);
          setLoadDocSuccess(true);
        }
      };
    } else if (!fileContext && separateInvoice && separatePackingList) {
      if (!separateInvoice && separatePackingList) return;

      const readerInvoice = new FileReader();
      readerInvoice.readAsArrayBuffer(separateInvoice!);

      const readerPackingList = new FileReader();
      readerPackingList.readAsArrayBuffer(separatePackingList!);

      readerInvoice.onload = async (event) => {
        if (event.target?.result) {
          const typedarray = new Uint8Array(event.target.result as ArrayBuffer);
          const pdf = await pdfjs.getDocument(typedarray).promise;

          const occurrences: IKeywordList[] = [];

          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const textContent = await page.getTextContent();
            textContent.items.forEach((item) => {
              if ("str" in item) {
                if (
                  ["invoice", "packing list"].some(
                    (keyword) =>
                      item.str.toLowerCase().includes(keyword.toLowerCase()) &&
                      item.str.length === keyword.length
                  )
                ) {
                  if (
                    !occurrences.find(
                      (e) => e.keyword.toLowerCase() === item.str.toLowerCase()
                    )
                  ) {
                    occurrences.push({
                      keyword: item.str,
                      startPage: i,
                      totalPage: pdf.numPages,
                    });
                  }
                }

                return item.str;
              }
            });
          }

          setGetKeywordSepInvoice(occurrences);
        }
      };

      readerPackingList.onload = async (event) => {
        if (event.target?.result) {
          const typedarray = new Uint8Array(event.target.result as ArrayBuffer);
          const pdf = await pdfjs.getDocument(typedarray).promise;

          const occurrences: IKeywordList[] = [];

          setTotalPage(pdf.numPages);

          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const textContent = await page.getTextContent();
            textContent.items.forEach((item) => {
              if ("str" in item) {
                if (
                  ["invoice", "packing list"].some(
                    (keyword) =>
                      item.str.toLowerCase().includes(keyword.toLowerCase()) &&
                      item.str.length === keyword.length
                  )
                ) {
                  if (
                    !occurrences.find(
                      (e) => e.keyword.toLowerCase() === item.str.toLowerCase()
                    )
                  ) {
                    occurrences.push({
                      keyword: item.str,
                      startPage: i,
                      totalPage: pdf.numPages,
                    });
                  }
                }

                return item.str;
              }
            });
          }

          setGetKeywordSepPacking(occurrences);
          setLoadDocSuccess(true);
        }
      };
    }
  };

  return (
    <>
      <Container className="preview-page" fluid>
        <Row className="justify-content-evenly">
          <div className="col-6 left-side text-center justify-content-center align-items-center">
            <div className="header-type-file-display d-flex justify-content-between align-items-center px-2 py-1 mb-3">
              <div className="font-bold-500">Choose type file</div>
              <div className="d-flex justify-content-center align-items-center gap-3">
                <div
                  className={`tab-type-file cursor-pointer ${
                    previewMode === "invoice" ? "active" : ""
                  }`}
                  onClick={() => {
                    setPreviewMode("invoice");
                  }}
                >
                  Invoice
                </div>
                {!fileContext && separateInvoice && separatePackingList && (
                  <div
                    className={`tab-type-file cursor-pointer ${
                      previewMode === "packing-list" ? "active" : ""
                    }`}
                    onClick={() => {
                      setPreviewMode("packing-list");
                    }}
                  >
                    Packing List
                  </div>
                )}
              </div>
            </div>
            {fileContext && !separateInvoice && !separatePackingList ? (
              <div className="box-display-pdf-area">
                <Document
                  ref={PdfRef}
                  file={fileContext}
                  onLoadSuccess={onDocumentLoadSuccess}
                  className="px-4"
                >
                  {fileContext &&
                    Array.from(new Array(numPages), (el, index) => (
                      <div className="body-page">
                        <div className="text-end font-bold-500">{`page ${
                          index + 1
                        }/${numPages}`}</div>
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                        />
                      </div>
                    ))}
                </Document>
              </div>
            ) : (
              <div className="box-display-pdf-area d-flex">
                <Document
                  ref={pdfRefSepInvoice}
                  file={separateInvoice}
                  onLoadSuccess={onDocumentSepInvoiceLoadSuccess}
                  className={`px-4 invoice-section ${
                    previewMode === "invoice" ? "active" : ""
                  }`}
                >
                  {separateInvoice &&
                    Array.from(new Array(numPagesInvoice), (el, index) => (
                      <div className="body-page">
                        <div className="text-end font-bold-500">{`page ${
                          index + 1
                        }/${numPagesInvoice}`}</div>
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                        />
                      </div>
                    ))}
                </Document>
                <Document
                  ref={pdfRefSepPackingList}
                  file={separatePackingList}
                  onLoadSuccess={onDocumentSepPackingLoadSuccess}
                  className={`px-4 packing-list-section ${
                    previewMode === "packing-list" ? "active" : ""
                  }`}
                >
                  {separatePackingList &&
                    Array.from(new Array(numPagesPackingList), (el, index) => (
                      <div className="body-page">
                        <div className="text-end font-bold-500">{`page ${
                          uploadMode === "multiple"
                            ? index + 1
                            : numPagesInvoice + index + 1
                        }/${
                          uploadMode === "multiple"
                            ? numPagesPackingList
                            : numPagesInvoice + numPagesPackingList
                        }`}</div>
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                        />
                      </div>
                    ))}
                </Document>
              </div>
            )}
          </div>
          <div className="col-4 right-side me-4">
            <div className="set-start-end-page col-4">
              <div className="header-section text-start">
                <div className="d-flex justify-content-between">
                  <div className="header-text font-bold-700">
                    Set Invoice & Packing list Section
                  </div>
                </div>
                <div className="description-text col-10">
                  If you would like to wrap from third to fifth and from seventh
                  to ninth words, you would enter "3-5,7-9" or if you want to
                  highlight whole text enter -1
                </div>

                <Form
                  onSubmit={(event) => {
                    event.preventDefault();
                  }}
                >
                  {displayMode !== DisplayFileModeEnum.PACKING_LIST && (
                    <div className="my-3">
                      <div className="font-bold-700">Invoice Page Position</div>
                      <Form.Group>
                        <Form.Label className="font-bold-500">
                          Start page
                        </Form.Label>
                        <Form.Control
                          type="number"
                          required
                          className="mb-3"
                          min={1}
                          value={startPageInvoice}
                          onChange={(event) => {
                            if (event.target.value) {
                              setStartPageInvoice(parseInt(event.target.value));
                            }
                          }}
                        />
                        <Form.Label className="font-bold-500">
                          End page
                        </Form.Label>
                        <Form.Control
                          type="number"
                          required
                          min={1}
                          value={endPageInvoice}
                          onChange={(event) => {
                            if (event.target.value) {
                              setEndPageInvoice(parseInt(event.target.value));
                            }
                          }}
                        />
                      </Form.Group>
                    </div>
                  )}
                  <div
                    className={`my-3 ${
                      (displayMode === "invoice" && getKeyword.length !== 0) ||
                      template === TemplateDocEnum.KING_POWER
                        ? "d-none"
                        : ""
                    }`}
                  >
                    <div className="font-bold-700">
                      Packing list Page Position
                    </div>
                    <Form.Group>
                      <Form.Label className="font-bold-500">
                        Start page
                      </Form.Label>
                      <Form.Control
                        type="number"
                        required
                        min={1}
                        className="mb-3"
                        value={startPagePackingList}
                        onChange={(event) => {
                          if (event.target.value) {
                            setStartPagePackingList(
                              parseInt(event.target.value)
                            );
                          }
                        }}
                      />
                      <Form.Label className="font-bold-500">
                        End page
                      </Form.Label>
                      <Form.Control
                        type="number"
                        required
                        min={1}
                        value={endPagePackingList}
                        onChange={(event) => {
                          if (event.target.value) {
                            setEndPagePackingList(parseInt(event.target.value));
                          }
                        }}
                      />
                    </Form.Group>
                  </div>
                  <div className="d-flex gap-3 justify-content-center mt-4">
                    <Button
                      variant="outline-secondary"
                      onClick={() => {
                        navigate(-1);
                        setSepInvoiceFile(undefined);
                        setSepPackListFile(undefined);
                        setContextFile(undefined);
                        setDisplayFileMode(DisplayFileModeEnum.INVOICE);
                      }}
                    >
                      {displayMode !== DisplayFileModeEnum.PACKING_LIST
                        ? "cancel"
                        : "Re-Upload"}
                    </Button>
                    {displayMode !== DisplayFileModeEnum.PACKING_LIST && (
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={
                          startPageInvoice === undefined ||
                          endPageInvoice === undefined
                        }
                        onClick={() => {
                          if (template === TemplateDocEnum.KING_POWER) {
                            navigate("/preview-ai-result", { replace: true });
                            return;
                          }

                          if (getKeyword.length === 0) {
                            if (
                              startPageInvoice !== undefined &&
                              endPageInvoice !== undefined &&
                              startPagePackingList === undefined &&
                              endPagePackingList === undefined
                            ) {
                              setDisplayFileMode(DisplayFileModeEnum.INVOICE);
                            } else if (
                              startPageInvoice !== undefined &&
                              endPageInvoice !== undefined &&
                              startPagePackingList !== undefined &&
                              endPagePackingList !== undefined
                            ) {
                              setDisplayFileMode(
                                DisplayFileModeEnum.INVOICE_PACKING_LIST
                              );
                            }
                            if (
                              fileContext &&
                              !separateInvoice &&
                              !separatePackingList
                            ) {
                              separatePDF(
                                startPageInvoice!,
                                endPageInvoice!,
                                startPagePackingList!,
                                endPagePackingList!,
                                true
                              );
                            }

                            navigate("/preview-ai-result", { replace: true });
                          } else {
                            navigate("/preview-ai-result", { replace: true });
                          }
                        }}
                      >
                        Confirm
                      </Button>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Row>
        {errorModalComponent}
      </Container>
    </>
  );
};

export default PreviewFilePage;

import React, { useState, useEffect, useRef } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";
import { Button, Form } from "react-bootstrap";

interface Option {
  value: string;
  label: string;
}

interface SearchableSelectProps {
  label?: string;
  value: string;
  onChange: (e: { currentTarget: { value: string } }) => void;
  options: Option[];
  size?: "sm" | "base" | "lg";
}

const SearchableSelect: React.FC<SearchableSelectProps> = ({
  label = "Label",
  value,
  onChange,
  options = [],
  size = "sm",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const filtered = options.filter((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredOptions(filtered);
  }, [searchTerm, options]);

  const selectedLabel = options.find((opt) => opt.value === value)?.label || "";

  return (
    <Form.Group className="mb-2" ref={wrapperRef}>
      <Form.Label className="block text-sm font-medium mb-1">
        {label}
      </Form.Label>
      <div className="d-flex">
        <Button
          type="button"
          size="sm"
          onClick={() => setIsOpen(!isOpen)}
          className="bg-white d-flex align-items-center col-12 justify-content-between"
          style={{ color: "black", border: "1px solid #bebebe" }}
        >
          <span className="truncate">{selectedLabel || "Select..."}</span>
          {isOpen ? (
            <ChevronUp
              size={18}
              className={`ms-1 ml-2 ${
                size === "sm" ? "w-4 h-4" : "w-5 h-5"
              } text-gray-500`}
            />
          ) : (
            <ChevronDown
              size={18}
              className={`ms-1 ml-2 ${
                size === "sm" ? "w-4 h-4" : "w-5 h-5"
              } text-gray-500`}
            />
          )}
        </Button>

        {isOpen && (
          <div
            className="absolute w-full bg-white border rounded shadow-sm"
            style={{ position: "absolute", marginTop: "2rem", zIndex: "20000" }}
          >
            <div className="p-2">
              <Form.Control
                type="text"
                className="w-full px-2 py-1 text-sm border rounded"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                autoFocus
              />
            </div>
            <div className="max-h-48 overflow-y-auto">
              {filteredOptions.map((option) => (
                <div
                  key={option.value}
                  className={`px-3 py-2 cursor-pointer hover:bg-gray-100 ${
                    value === option.value ? "bg-blue-50" : ""
                  } ${size === "sm" ? "text-sm" : "text-base"}`}
                  onClick={() => {
                    onChange({ currentTarget: { value: option.value } });
                    setIsOpen(false);
                    setSearchTerm("");
                  }}
                >
                  {option.label}
                </div>
              ))}
              {filteredOptions.length === 0 && (
                <div className="px-3 py-2 text-gray-500 text-sm">
                  No results found
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Form.Group>
  );
};

export default SearchableSelect;

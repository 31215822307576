import { KeyProcessDetailEnum, resultProcessEnum } from "../enum/processType";
import { IDataKingPowerMap } from "../interface/mapData";
import { IProcessResultProps } from "../interface/processResult";
import * as XLSX from "xlsx";

export interface IExportCsvKingPowerReturn {
  dataInvoiceCsv: XLSX.WorkBook;
  firstInvoiceNo: string;
  dataInvoiceFileSize: number;
}

interface GroupedData {
  [page: string]: IProcessResultProps[];
}

export function exportDataKKingPowerToCsv(
  processInvoiceResult: IProcessResultProps[]
): IExportCsvKingPowerReturn {
  const mapData: IDataKingPowerMap[] = [];

  const groupData = processInvoiceResult.reduce<GroupedData>((acc, item) => {
    const page = item.hightLightZone.page;
    if (!acc[page]) {
      acc[page] = [];
    }
    acc[page].push(item);
    return acc;
  }, {});

  const getKeyPage = Object.keys(groupData);

  for (let index = 0; index < getKeyPage.length; index++) {
    const invoiceNo = groupData[getKeyPage[index]].find(
      (e) => e.key === KeyProcessDetailEnum.KING_POWER_INVOICE_INVOICE_NO
    );

    const customerRef = groupData[getKeyPage[index]].find(
      (e) => e.key === KeyProcessDetailEnum.KING_POWER_INVOICE_CUSTOMER_REF
    );

    const description = groupData[getKeyPage[index]].filter(
      (e) => e.key === KeyProcessDetailEnum.KING_POWER_INVOICE_DESCRIPTION
    );

    const item = groupData[getKeyPage[index]].filter(
      (e) => e.key === KeyProcessDetailEnum.KING_POWER_INVOICE_ITEM
    );

    const madeIn = groupData[getKeyPage[index]].filter(
      (e) => e.key === KeyProcessDetailEnum.KING_POWER_INVOICE_MADE_IN
    );

    const qty = groupData[getKeyPage[index]].filter(
      (e) => e.key === KeyProcessDetailEnum.KING_POWER_INVOICE_QTY
    );

    const unitPrice = groupData[getKeyPage[index]].filter(
      (e) => e.key === KeyProcessDetailEnum.KING_POWER_INVOICE_UNIT_PRICE
    );

    let customItem = 0;

    item.forEach((e, indexItem) => {
      let filterLetters = "";
      if (customerRef) {
        filterLetters = customerRef.resultText
          .split("")
          .filter((char) => /[0-9]/.test(char))
          .join("");
      }
      mapData.push({
        Invoice: invoiceNo ? invoiceNo.resultText ?? "" : "",
        Ref: e.resultText ?? "",
        Description: description[indexItem]
          ? description[indexItem].resultText ?? ""
          : "",
        QTY: qty[indexItem] ? qty[indexItem].resultText ?? "" : "",
        Price: unitPrice[indexItem]
          ? unitPrice[indexItem].resultText ?? ""
          : "",
        "Customs item": (mapData.length + 1) as unknown as string,
        Origin: madeIn[indexItem] ? madeIn[indexItem].resultText ?? "" : "",
        Search: "",
        "Invoice Unit": "",
        PO: filterLetters,
      });
      customItem += 1;
    });
  }
  // Convert the array of objects to a worksheet
  const dataSheet = XLSX.utils.json_to_sheet(mapData);

  // Create a new workbook
  const wb = XLSX.utils.book_new();

  // Append worksheets to the workbook
  XLSX.utils.book_append_sheet(wb, dataSheet, "data");

  // Write the workbook to a file and trigger the download
  const dataCsv = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(dataCsv, dataSheet, "data");

  // Convert the workbook to a binary string
  const dataCsvBinary = XLSX.write(dataCsv, { type: "binary" });

  // Calculate the size of the binary string
  const dataCsvSize = dataCsvBinary.length;

  const invoiceNo = mapData.find((e) => e.Invoice);

  return {
    firstInvoiceNo: invoiceNo?.Invoice ?? "",
    dataInvoiceCsv: dataCsv,
    dataInvoiceFileSize: dataCsvSize,
  };
}

export enum ProcessTypeEnum {
  INVOICE = 1,
  PACKING_LIST = 2,
}

export enum KeyProcessHeaderEnum {
  CMCODE = "CMCODE",
  INVNO = "INVNO",
  INVDATE = "INVDATE",
  JOBCODE = "JOBCODE",
  SOLDCODE = "SOLDCODE",
  SOLDATT1 = "SOLDATT1",
  COMLV = "COMLV",
  FSELLER_M = "FSELLER_M",
  FSELLER2_M = "FSELLER2_M",
  FSELLAD1_C = "FSELLAD1_C",
  FSELLAD2_C = "FSELLAD2_C",
  FSELLAD3_C = "FSELLAD3_C",
  FSELLAD4_C = "FSELLAD4_C",
  SELLCTRY = "SELLCTRY",
  SELLPOST = "SELLPOST",
  SELLMAIL = "SELLMAIL",
  PURCTRY = "PURCTRY",
  DESCTRY = "DESCTRY",
  EXIMDATE = "EXIMDATE",
  TERM = "TERM",
  INVCURR = "INVCURR",
  INVTOT = "INVTOT",
  FORCURR = "FORCURR",
  FORCH = "FORCH",
  FRICURR = "FRICURR",
  FRICH = "FRICH",
  INSCURR = "INSCURR",
  INSCH = "INSCH",
  LOAD_PORT = "LOAD_PORT",
  RELE_PORT = "RELE_PORT",
  VESNAME1 = "VESNAME1",
  TOTPACK = "TOTPACK",
  TOTPACKCHR = "TOTPACKCHR",
  TOTNETW = "TOTNETW",
  TOTGROW = "TOTGROW",
  PACKU = "PACKU",
  NETWU = "NETWU",
  GROWU = "GROWU",
  FBL_N = "FBL_N",
  PAYTERM = "PAYTERM",
  DELITERM = "DELITERM",
  MAWB = "MAWB",
  SHIPMARK = "SHIPMARK",
  PONO = "PONO",
  PACKCHGCURR = "PACKCHGCURR",
  PACKCHG = "PACKCHG",
  FORINCHGCURR = "FORINCHGCURR",
  FORINCHG = "FORINCHG",
  LANDINGCHGCURR = "LANDINGCHGCURR",
  LANDINGCHG = "LANDINGCHG",
  OTHCHGCURR = "OTHCHGCURR",
  OTHCHG = "OTHCHG",
  DISCOUNT = "DISCOUNT",
  DISC_PERC = "DISC_PERC",
  REFNOCOMACC = "REFNOCOMACC",
  USESIGNNET = "USESIGNNET",
  EXPORTTAX_INCEN_ID = "EXPORTTAX_INCEN_ID",
  TRAN_MODE = "TRAN_MODE",
  VOY = "VOY",
  CARGOPACKTYPE = "CARGOPACKTYPE",
  DOCTYPE = "DOCTYPE",
  CCARD = "CCARD",
  CSCARD = "CSCARD",
}

export enum KeyProcessDetailEnum {
  INVNO = "INVNO",
  INVDATE = "INVDATE",
  PRODUCT_ID = "PRODUCT_ID",
  GROUP_ROW_INDEX = "GROUP_ROW_INDEX",
  FOC = "FOC",
  ORICTRY = "ORICTRY",
  TYPECAL = "TYPECAL",
  TARCL = "TARCL",
  TARST = "TARST",
  TARSE = "TARSE",
  TARST_U = "TARST_U",
  RTCPRD = "RTCPRD",
  PRDYEAR = "PRDYEAR",
  WEIGHT = "WEIGHT",
  WEIGHTU = "WEIGHTU",
  QTY = "QTY",
  QTYU = "QTYU",
  INVCURR = "INVCURR",
  PRICE = "PRICE",
  INVQTY = "INVQTY",
  INVQTYU = "INVQTYU",
  AMOUNT = "AMOUNT",
  BRAND = "BRAND",
  PURCTRY = "PURCTRY",
  PROATT1 = "PROATT1",
  PROATT2 = "PROATT2",
  FORCURR = "FORCURR",
  FORF = "FORF",
  FRICURR = "FRICURR",
  FRI = "FRI",
  INSCURR = "INSCURR",
  INS = "INS",
  // TODO : use for mockup only
  DES = "DES",
  DES1 = "DES1",
  DES2 = "DES2",
  DES3 = "DES3",
  DES4 = "DES4",
  THDES1 = "THDES1",
  THDES2 = "THDES2",
  THDES3 = "THDES3",
  THDES4 = "THDES4",
  EXEMPTDUTY = "EXEMPTDUTY",
  PRIVILEGE = "PRIVILEGE",
  TERM = "TERM",
  MR1 = "MR1",
  MR2 = "MR2",
  MR3 = "MR3",
  MR4 = "MR4",
  MR5 = "MR5",
  MR6 = "MR6",
  NUMPKG = "NUMPKG",
  UNITPKG = "UNITPKG",
  COMPEN = "COMPEN",
  BOIUSE = "BOIUSE",
  BOI = "BOI",
  BOND = "BOND",
  BONDFML = "BONDFML",
  M19 = "M19",
  FORMULAR_C = "FORMULAR_C",
  FORM19TRANS = "FORM19TRANS",
  MODEL_VER = "MODEL_VER",
  MODEL_COMPTAXNO = "MODEL_COMPTAXNO",
  UNDG = "UNDG",
  REIMP = "REIMP",
  REEXP = "REEXP",
  FREEZONE = "FREEZONE",
  EPZ = "EPZ",
  IMPORTTAX_INCEN_ID = "IMPORTTAX_INCEN_ID",
  IMPDECNO = "IMPDECNO",
  IMPDECL = "IMPDECL",
  PERMIT_N = "PERMIT_N",
  PERMIT_ISSUE = "PERMIT_ISSUE",
  PERMIT_DATE = "PERMIT_DATE",
  PERMIT_N2 = "PERMIT_N2",
  PERMIT_ISSUE2 = "PERMIT_ISSUE2",
  PERMIT_DATE2 = "PERMIT_DATE2",
  PERMIT_N3 = "PERMIT_N3",
  PERMIT_ISSUE3 = "PERMIT_ISSUE3",
  PERMIT_DATE3 = "PERMIT_DATE3",
  REMARK = "REMARK",
  ASSESSVALUE = "ASSESSVALUE",
  ARG_TARIFF = "ARG_TARIFF",
  ARG_TARSE = "ARG_TARSE",
  ORIGIN_CRITERIA = "ORIGIN_CRITERIA",
  SELFCERT = "SELFCERT",
  CERT_EXPNO = "CERT_EXPNO",
  NATURETRAN = "NATURETRAN",
  FORMULA_BOI = "FORMULA_BOI",
  SELFCERT_NOT_INVNO = "SELFCERT_NOT_INVNO",
  INCRCURR = "INCRCURR",
  INCREXCH = "INCREXCH",
  PONO_DET = "PONO_DET",

  KING_POWER_INVOICE_INVOICE_NO = "INVOICE_NO",
  KING_POWER_INVOICE_CUSTOMER_REF = "CUSTOMER_REF",
  KING_POWER_INVOICE_DESCRIPTION = "DESCRIPTION",
  KING_POWER_INVOICE_ITEM = "ITEM",
  KING_POWER_INVOICE_MADE_IN = "MADE_IN",
  KING_POWER_INVOICE_QTY = "QTY",
  KING_POWER_INVOICE_UNIT_PRICE = "UNIT_PRICE",
}

export enum resultProcessEnum {
  CMCODE = "CMCODE",
  INVNO = "INVNO",
  INVDATE = "INVDATE",
  JOBCODE = "JOBCODE",
  SOLDCODE = "SOLDCODE",
  SOLDATT1 = "SOLDATT1",
  COMLV = "COMLV",
  FSELLER_M = "FSELLER_M",
  FSELLER2_M = "FSELLER2_M",
  FSELLAD1_C = "FSELLAD1_C",
  FSELLAD2_C = "FSELLAD2_C",
  FSELLAD3_C = "FSELLAD3_C",
  FSELLAD4_C = "FSELLAD4_C",
  SELLCTRY = "SELLCTRY",
  SELLPOST = "SELLPOST",
  SELLMAIL = "SELLMAIL",
  PURCTRY = "PURCTRY",
  DESCTRY = "DESCTRY",
  EXIMDATE = "EXIMDATE",
  TERM = "TERM",
  INVCURR = "INVCURR",
  INVTOT = "INVTOT",
  FORCURR = "FORCURR",
  FORCH = "FORCH",
  FRICURR = "FRICURR",
  FRICH = "FRICH",
  INSCURR = "INSCURR",
  INSCH = "INSCH",
  LOAD_PORT = "LOAD_PORT",
  RELE_PORT = "RELE_PORT",
  VESNAME1 = "VESNAME1",
  TOTPACK = "TOTPACK",
  TOTPACKCHR = "TOTPACKCHR",
  TOTNETW = "TOTNETW",
  TOTGROW = "TOTGROW",
  PACKU = "PACKU",
  NETWU = "NETWU",
  GROWU = "GROWU",
  FBL_N = "FBL_N",
  PAYTERM = "PAYTERM",
  DELITERM = "DELITERM",
  MAWB = "MAWB",
  SHIPMARK = "SHIPMARK",
  PONO = "PONO",
  PACKCHGCURR = "PACKCHGCURR",
  PACKCHG = "PACKCHG",
  FORINCHGCURR = "FORINCHGCURR",
  FORINCHG = "FORINCHG",
  LANDINGCHGCURR = "LANDINGCHGCURR",
  LANDINGCHG = "LANDINGCHG",
  OTHCHGCURR = "OTHCHGCURR",
  OTHCHG = "OTHCHG",
  DISCOUNT = "DISCOUNT",
  DISC_PERC = "DISC_PERC",
  REFNOCOMACC = "REFNOCOMACC",
  USESIGNNET = "USESIGNNET",
  EXPORTTAX_INCEN_ID = "EXPORTTAX_INCEN_ID",
  TRAN_MODE = "TRAN_MODE",
  VOY = "VOY",
  CARGOPACKTYPE = "CARGOPACKTYPE",
  DOCTYPE = "DOCTYPE",
  CCARD = "CCARD",
  CSCARD = "CSCARD",
}
